<template>
  <v-app :theme="currentTheme" :style="{ 'height': windowHeight + 'px' }" ref="appWindow">
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col class="pa-0 ma-0" cols="12" ref="cockpitCameraMoveableContainer">
            <GoogleMap class="pa-0 ma-0" ref="trackingMap" :api-key="googleAPIKey" :mapId="trainMapId"
              :style="{ width: '100%', height: (showSelectedTrainDebugInfo ? (windowHeight - 200) : windowHeight - 5) + 'px', '--info-close-color': (darkThemeSwitch ? 'white' : 'black'), '--info-bg-color': (darkThemeSwitch ? 'rgb(33,33,33)' : 'white'), '--info-text-color': (darkThemeSwitch ? 'white' : 'black') }"
              :clickable-icons="true" :keyboard-shortcuts="false" :map-type-id="trackingMapOptions.mapType"
              :mapTypeControl="false" :zoomControl="false" :fullscreen-control="false" :rotate-control="false"
              :scale-control="false" :street-view-control="false" @zoom_changed="trackingMapZoomChanged"
              @tilt_changed="trackingMapTiltChanged" @idle="trackingMapIdleEvent"
              @heading_changed="trackingMapHeadingChanged" @dragstart="mapDragStarted"
              @maptypeid_changed="trackingMapTypeChanged">
              <!-- Custom Map Type Control -->
              <CustomControl position="TOP_RIGHT">
                <div v-if="mdAndUp" class="d-flex align-center justify-center mr-2 mt-2 ml-2">
                  <v-btn-toggle v-model="selectedMapType" divided dense mandatory
                    @update:modelValue="mapTypeSelectEvent" style="height: auto;">
                    <v-btn size="x-small" height="20" value="roadmap">
                      Roadmap
                    </v-btn>

                    <v-btn size="x-small" height="20" value="terrain">
                      Roadmap w/ Terrain
                    </v-btn>

                    <v-btn size="x-small" height="20" value="satellite">
                      Satellite
                    </v-btn>

                    <v-btn size="x-small" height="20" value="hybrid">
                      Hybrid
                    </v-btn>
                  </v-btn-toggle>
                  <v-btn-toggle class="ml-2" dense divided @update:modelValue="mapTypeSelectEvent"
                    style="height: auto;">
                    <v-btn size="x-small" height="20" :icon="'mdi-fullscreen'" @click="activateMapFullscreen"></v-btn>
                  </v-btn-toggle>
                </div>
              </CustomControl>
              <!-- Custom Map Zoom, Tilt, Heading Controls -->
              <CustomControl :position="'RIGHT_TOP'">
                <v-card v-show="mdAndUp" width="250" height="120" elevation="8" class="mr-2 mt-1">
                  <v-card-item>
                    <v-form>
                      <div class="d-flex justify-center align-center">
                        <v-slider class="mt-1 pt-0" v-model="mapZoomSliderValue" :thumb-size="12" hide-details
                          append-icon="mdi-magnify-plus-outline" prepend-icon="mdi-magnify-minus-outline"
                          :max="mapZoomSliderMax" :min="mapZoomSliderMin"
                          @click:append="mapZoomSliderUpdated(++mapZoomSliderValue)"
                          @click:prepend="mapZoomSliderUpdated(--mapZoomSliderValue)"
                          @update:modelValue="mapZoomSliderUpdated"></v-slider>
                        <p>{{ mapZoomSliderValue.toPrecision(2) }}</p>
                        <!-- <v-text-field v-model="mapZoomSliderValue" readonly style="width: 5px; height: 20px;" density="compact" hide-details
                          variant="outlined"></v-text-field> -->
                      </div>
                      <div class="d-flex justify-center align-center">
                        <v-slider v-model="mapHeadingSliderValue" :thumb-size="12" hide-details label="Heading"
                          prepend-icon="mdi-axis-z-rotate-counterclockwise" :max="mapHeadingSliderMax"
                          :min="mapHeadingSliderMin"
                          @click:prepend="mapHeadingSliderValue = 0; mapHeadingSliderUpdated(mapHeadingSliderValue)"
                          @update:modelValue="mapHeadingSliderUpdated"></v-slider>
                        <p>{{ mapHeadingSliderValue > 99.9 ? mapHeadingSliderValue.toPrecision(3) :
                          mapHeadingSliderValue.toPrecision(2) }}°</p>
                        <!-- <v-text-field v-model="mapHeadingSliderValue" readonly style="width: 5px; height: 20px;" density="compact" hide-details
                          variant="outlined"></v-text-field> -->
                      </div>
                      <div class="d-flex justify-center align-center">
                        <v-slider v-model="mapTiltSliderValue" append-icon="mdi-angle-obtuse" :thumb-size="12"
                          hide-details prepend-icon="mdi-angle-right" label="Tilt" :max="mapTiltSliderMax"
                          :min="mapTiltSliderMin" @click:append="mapTiltSliderUpdated(++mapTiltSliderValue)"
                          @click:prepend="mapTiltSliderUpdated(--mapTiltSliderValue)"
                          @update:modelValue="mapTiltSliderUpdated"></v-slider>
                        <p>{{ (mapTiltSliderValue + 90).toPrecision(3) }}°</p>
                        <!-- <v-text-field v-model="mapTiltSliderValue" readonly style="width: 5px; height: 20px;" density="compact" hide-details
                          variant="outlined"></v-text-field> -->
                      </div>
                    </v-form>
                  </v-card-item>
                </v-card>
              </CustomControl>
              <!-- App Bar / Train Selector Panel -->
              <CustomControl :position="'TOP_LEFT'">
                <div class="ma-0 pa-0" :style="appBarUIstyle()">
                  <v-card :width="Math.min(475, (mdAndUp ? windowWidth - 32 : (1.333 * windowWidth) - 32))"
                    height="auto" elevation="8" class="ml-2 mt-2">
                    <v-layout>
                      <v-app-bar dense height="45">
                        <v-app-bar-nav-icon>
                          <img style="width: 30px; height: 30px;" :src="require('@/assets/Amtrak-Emblem.jpg')" />
                        </v-app-bar-nav-icon>
                        <div class="d-flex justify-center align-center">
                          <v-toolbar-title>{{ componentTitle }}</v-toolbar-title>
                          <v-btn size="x-small" variant="plain" disabled>
                            v{{ projectVersion }}
                          </v-btn>
                        </div>
                        <template v-slot:append>
                          <div class="d-flex justify-center align-center">
                            <v-btn size="x-small" icon="mdi-cog" @click="infoClicked"></v-btn>
                            <!-- <v-switch v-model="darkThemeSwitch" hide-details
                              :prepend-icon="darkThemeSwitch ? 'mdi-weather-night' : 'mdi-white-balance-sunny'">
                            </v-switch> -->
                          </div>
                        </template>
                      </v-app-bar>
                      <v-main>
                        <v-container fluid>
                          <v-row>
                            <v-col>
                              <v-form>
                                <!-- Combobox on larger screens (keyboard search support)-->
                                <template v-if="mdAndUp">
                                  <v-combobox class="mt-2" :items="sortedRoutes" prepend-icon="mdi-railroad-light"
                                    density="compact" label="Route" variant="outlined" hide-details="auto"
                                    no-data-text="Loading..." :modelValue="selectedRoute"
                                    @update:modelValue="routeSelectEvent">
                                  </v-combobox>
                                  <v-combobox class="mt-2" :items="trainLabelsForRoute" prepend-icon="mdi-train-variant"
                                    density="compact" label="Train" variant="outlined" hide-details="auto"
                                    no-data-text="Loading..." :modelValue="selectedTrainForSelect"
                                    @update:modelValue="trainSelectEvent">
                                    <template v-slot:selection="{ item }">
                                      <span
                                        :style="{ color: (trainLabelObjectForTitle(item.title)?.removed ? removedTrainItemColor : undefined )}">{{
                                        item.title }} ({{ trainDataForTitle(item.title)?.trainState }}){{ trainDataForTitle(item.title)?.serviceDisruption ? ' (SERVICE ISSUE)' : '' }}</span>
                                    </template>
                                    <template v-slot:item="{ item, props }">
                                      <v-list-item
                                        :style="{ color: (trainLabelObjectForTitle(item.title)?.removed ? removedTrainItemColor : undefined )}"
                                        v-bind="props"
                                        :title="`${item.title} (${trainDataForTitle(item.title)?.trainState})${trainDataForTitle(item.title)?.serviceDisruption ? ' (SERVICE ISSUE)' : ''}`">
                                      </v-list-item>
                                    </template>
                                  </v-combobox>
                                </template>
                                <template v-else>
                                  <v-select class="mt-2" :items="sortedRoutes" prepend-icon="mdi-railroad-light"
                                    density="compact" label="Route" variant="outlined" hide-details="auto"
                                    no-data-text="Loading..." :modelValue="selectedRoute"
                                    @update:modelValue="routeSelectEvent">
                                  </v-select>
                                  <v-select class="mt-2" :items="trainLabelsForRoute" prepend-icon="mdi-train-variant"
                                    density="compact" label="Train" variant="outlined" hide-details="auto"
                                    no-data-text="Loading..." :modelValue="selectedTrainForSelect"
                                    @update:modelValue="trainSelectEvent">
                                    <template v-slot:selection="{ item }">
                                      <span
                                        :style="{ color: (trainLabelObjectForTitle(item.title)?.removed ? removedTrainItemColor : undefined )}">{{
                                        item.title }} ({{ trainDataForTitle(item.title)?.trainState }}){{ trainDataForTitle(item.title)?.serviceDisruption ? ' (SERVICE ISSUE)' : '' }}</span>
                                    </template>
                                    <template v-slot:item="{ item, props }">
                                      <v-list-item
                                        :style="{ color: (trainLabelObjectForTitle(item.title)?.removed ? removedTrainItemColor : undefined )}"
                                        v-bind="props"
                                        :title="`${item.title} (${trainDataForTitle(item.title)?.trainState})${trainDataForTitle(item.title)?.serviceDisruption ? ' (SERVICE ISSUE)' : ''}`">
                                      </v-list-item>
                                    </template>
                                  </v-select>
                                </template>
                                <!-- Select on smaller screens (no keyboard)-->

                                <div class="d-flex align-center justify-center">
                                  <v-checkbox class="smaller-v-checkbox mt-2 pt-0 mr-8" hide-details="auto"
                                    density="compact" v-model="followSelectedTrain" label="Watch"></v-checkbox>
                                  <v-checkbox :disabled="!followSelectedTrain" class="smaller-v-checkbox mt-2 pt-0 mr-8"
                                    hide-details="auto" density="compact" v-model="chaseSelectedTrain"
                                    label='"Aerial Chase"'></v-checkbox>
                                  <v-checkbox v-if="mdAndUp" class="smaller-v-checkbox mt-2 pt-0" hide-details="auto"
                                    density="compact" v-model="showSelectedTrainCockpitView" label="Train Camera">
                                  </v-checkbox>
                                </div>
                                <v-progress-linear theme="dark" :model-value="trainTransitionProgressPercent"
                                  :height="12" style="font-size: 11px; line-height: normal;" color="#005179">
                                  {{ trainTransitionProgressContent }}
                                </v-progress-linear>
                              </v-form>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-main>
                    </v-layout>
                  </v-card>
                  <!-- Custom Map Type Control (Small Screens) -->
                  <div v-if="!mdAndUp" class="d-flex align-center justify-center mt-1">
                    <v-btn-toggle v-model="selectedMapType" divided dense mandatory
                      @update:modelValue="mapTypeSelectEvent" style="height: auto;">
                      <v-btn size="x-small" height="20" value="roadmap">
                        Roadmap
                      </v-btn>

                      <v-btn size="x-small" height="20" value="terrain">
                        Roadmap w/ Terrain
                      </v-btn>

                      <v-btn size="x-small" height="20" value="satellite">
                        Satellite
                      </v-btn>

                      <v-btn size="x-small" height="20" value="hybrid">
                        Hybrid
                      </v-btn>
                    </v-btn-toggle>
                    <v-btn-toggle class="ml-2" dense divided @update:modelValue="mapTypeSelectEvent"
                      style="height: auto;">
                      <v-btn size="x-small" height="20" :icon="'mdi-fullscreen'" @click="activateMapFullscreen"></v-btn>
                    </v-btn-toggle>
                  </div>
                </div>
              </CustomControl>
              <!-- Route Segment Cache Controls -->
              <CustomControl :position="'TOP_CENTER'" :style="{ left: `${(windowWidth / 2 - 200)}px` }">
                <v-expansion-panels class="mt-2" v-show="mdAndUp && adminKey" v-model="cacheViewerExpansionPanelState">
                  <v-expansion-panel value="cacheViewer">
                    <v-expansion-panel-title expand-icon="mdi-chevron-up" collapse-icon="mdi-chevron-down" style="min-width: 400px;">
                      Track Segment Cache Editor
                    </v-expansion-panel-title>
                    <v-expansion-panel-text class="ma-0 pa-0" id="cacheViewerContent">
                      <v-card :width="Math.min(400, (mdAndUp ? windowWidth - 32 : (1.333 * windowWidth) - 32))"
                    height="auto">
                    <v-form>
                             <!-- Need vars for allRoutes, allStationSegmentsForTrain -->
                             <v-combobox class="mt-2" :items="cacheViewerRoutes" prepend-icon="mdi-railroad-light"
                                density="compact" label="Route" variant="outlined" hide-details="auto"
                                no-data-text="Loading..." :modelValue="cacheViewerRoute" @update:modelValue="cacheViewerRouteSelected">
                              </v-combobox>
                              <v-combobox class="mt-2" :items="cacheViewerSegments" prepend-icon="mdi-fence"
                                density="compact" label="Segment" variant="outlined" hide-details="auto"
                                no-data-text="Loading..." :modelValue="selectedCacheViewerSegment" @update:modelValue="cacheViewerSegmentSelected">
                              </v-combobox>
                              <v-combobox class="mt-2" :items="cacheViewerTrains" prepend-icon="mdi-train-variant"
                                density="compact" label="Train" variant="outlined" hide-details="auto"
                                no-data-text="Loading..." :modelValue="selectedCacheViewerTrain" @update:modelValue="cacheViewerTrainSelected">
                              </v-combobox>
                              <v-file-input
                                class="mt-2"
                                v-show="(cacheViewerRoute?.[0] && selectedCacheViewerSegment?.[0])"
                                prepend-icon="mdi-code-json"
                                variant="outlined"
                                hide-details="auto"
                                density="compact"
                                accept=".json,.txt"
                                label="Import (GeoJSON)"
                                @update:modelValue="cacheViewerImportGeoJSONSelected"
                              ></v-file-input>
                            <div class="d-flex align-center justify-center">
                              <!-- Button(s) for rendering segment -->
                              <div class="pt-2 pb-2 d-flex flex-column justify-center align-center">
                                <v-btn 
                                  class="py-1"
                                  size="small"
                                  :disabled="(!selectedCacheViewerSegmentPath || selectedCacheViewerSegmentPath.length === 0)"
                                  @click="exportSegmentCacheClicked"
                                  >Export (GEOJson)</v-btn>
                              </div>
                               <div class="pt-2 pl-2 pb-2 d-flex flex-column justify-center align-center">
                                  <v-btn
                                    :disabled="!(selectedCacheViewerSegment?.[0] && selectedCacheViewerTrain?.[0])"
                                    class="pb-1 pt-1"
                                    size="small"
                                    @click="cacheViewerRenderSelected">Re-Render</v-btn>
                               </div>
                               <div class="pl-2 pt-2 pb-2 d-flex flex-column justify-center align-center">
                                  <v-btn
                                    :disabled="!adminKey || !cacheViewerRenderedPath || cacheViewerRenderedPath.length === 0"
                                    class="pb-1 pt-1"
                                    size="small"
                                    @click="cacheViewerPushRenderSelected">Upload</v-btn>
                               </div>
                            </div>
                            <div>Path Finder Settings:</div>
                            <div class="pl-2 d-flex justify-center align-center">
                              <v-slider v-model="pathFinderSegmentPointsMax" :thumb-size="12" hide-details
                                label="Max Data Points" :max="30000" :min="500"
                                :step="500"></v-slider>
                              <p>{{ pathFinderSegmentPointsMax }}</p>
                            </div>
                            <div class="pl-2 d-flex align-center">
                              <!-- Show the source data points -->
                              <v-checkbox class="smaller-v-checkbox" hide-details="auto"
                                density="compact" v-model="showCacheViewerSegmentPoints" label="Show Data Points"></v-checkbox>
                            </div>
                            <div class="pl-2 d-flex justify-center align-center">
                              <v-slider v-model="pathFinderMaxTrackCurvatureDegrees" :thumb-size="12" hide-details
                                label="Max Track Curvature (Degrees)" :max="30" :min="2" :step="0.5"></v-slider>
                              <p>{{ pathFinderMaxTrackCurvatureDegrees > 99.9 ? pathFinderMaxTrackCurvatureDegrees.toPrecision(3) :
                                pathFinderMaxTrackCurvatureDegrees.toPrecision(2) }}°</p>
                            </div>
                            <div class="pl-2 d-flex justify-center align-center">
                              <v-slider v-model="pathFinderMaxTrackCurvatureRangeFeet" :thumb-size="12" hide-details
                                label="Max Track Curvature Range (Feet)" :max="5000" :min="0" :step="100"></v-slider>
                              <p>{{ pathFinderMaxTrackCurvatureRangeFeet }}</p>
                            </div>
                            <div class="pl-2 d-flex justify-center align-center">
                              <v-slider v-model="pathFinderMaxTrackCurvatureSkip" :thumb-size="12" hide-details
                                label="Track Curve Skip Max" :max="200" :min="0" :step="1"></v-slider>
                              <p>{{ pathFinderMaxTrackCurvatureSkip > 99.9 ? pathFinderMaxTrackCurvatureSkip.toPrecision(3) :
                                pathFinderMaxTrackCurvatureSkip.toPrecision(2) }} points</p>
                            </div>
                            <div class="pl-2 d-flex justify-center align-center">
                              <v-slider v-model="pathFinderHintFailsafeThresholdRatio" :thumb-size="12" hide-details
                                label="Hint Path Failsafe Threshold Ratio" :max="50" :min="2" :step="1"></v-slider>
                              <p>{{ pathFinderHintFailsafeThresholdRatio }}</p>
                            </div>
                          </v-form>
                  </v-card>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </CustomControl>
              <!-- Selected Train Info -->
              <CustomControl :position="'LEFT_BOTTOM'">
                <div class="ma-0 pa-0" :style="trainInfoUIstyle()">
                  <v-expansion-panels v-model="expansionPanelState" :class="mdAndUp ? 'ml-2 mb-6' : 'ml-2 mb-1'"
                    :width="(mdAndUp ? undefined : (1.333 * windowWidth) - 32)">
                    <v-expansion-panel v-if="selectedTrain" value="selectedTrainDetails" elevation="8">
                      <v-expansion-panel-title v-if="selectedTrain" expand-icon="mdi-chevron-up"
                        collapse-icon="mdi-chevron-down">
                        {{selectedTrain.data.routeName}} #{{selectedTrain.data.trainNum }} ({{
                        selectedTrain.data.trainState }}){{ selectedTrain.data.serviceDisruption ? ' (SERVICE ISSUE)' : '' }}</v-expansion-panel-title>
                      <v-expansion-panel-text v-if="selectedTrain" style="font-size: small; line-height: normal;">
                        <div>Origin: {{
                          trainData.trainStationsByCode[selectedTrain.data.origCode].properties.StationName }} ({{
                          selectedTrain.data.origCode }}), {{ prettyDateWithFormat(new
                          Date(selectedTrain.data.origSchDep),
                          'MM-DD-YY')
                          }}
                        </div>
                        <div>Destination: {{
                          trainData.trainStationsByCode[selectedTrain.data.destCode].properties.StationName }} ({{
                          selectedTrain.data.destCode }})
                        </div>
                        <div>Location: {{ selectedTrainLocation }}</div>
                        <div v-if="selectedTrain.data.velocity !== null && selectedTrain.data.heading">
                          Speed:
                          {{
                          selectedTrain.data.velocity >= 100 ? selectedTrain.data.velocity.toPrecision(3) :
                          selectedTrain.data.velocity.toPrecision(2)
                          }}mph ({{ selectedTrain.data.heading }}{{ selectedTrain.data.headingDegrees != undefined ? `,
                          ${selectedTrain.data.headingDegrees.toPrecision(4)}°` : '' }})</div>
                        <div>Train Time: {{ selectedTrain.data.lastValTrainTS }}</div>
                        <div>Train Elevation (ft): {{ selectedTrainElevation }}</div>
                        <div>Local Weather: {{ selectedTrainWeather }}</div>
                        <div v-if="selectedTrain.data.lastStation">Previous Stop: {{
                          selectedTrain.data.lastStation.code
                          }} ({{
                          selectedTrain.data.lastStation.info?.properties.StationName ?? '----'
                          }})</div>
                        <div v-if="selectedTrain.data.currentStation">Current Stop: {{
                          selectedTrain.data.currentStation.code }}
                          ({{
                          selectedTrain.data.currentStation.info.properties.StationName }})
                          (Est. Depart {{ selectedTrain.data.currentStation.estDep
                          ? humanTimeSinceDate(new Date(selectedTrain.data.currentStation.estDep))
                          : 'Unknown' }})
                        </div>
                        <template v-if="selectedTrain.data.nextStation">
                          <div>Next Stop: {{ selectedTrain.data.nextStation.code }} ({{
                            selectedTrain.data.nextStation.info?.properties.StationName ?? '----' }}) {{
                            selectedTrain.data.nextStation.estArr ? `(ETA ${humanTimeSinceDate(new
                            Date(selectedTrain.data.nextStation.estArr))})` : ''}}</div>
                        </template>
                        <div>Last Updated: {{ humanTimeSinceDate(new Date(selectedTrain.data.lastValTS)) }}
                        </div>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </CustomControl>
              <!-- Train News -->
              <CustomControl v-show="mdAndUp" :position="'RIGHT_BOTTOM'">
                <v-expansion-panels v-model="genralInfoExpansionPanelState">
                  <v-expansion-panel value="trainNewsItems" elevation="8" class="mr-2 mb-9" style="max-width: 350px;">
                    <v-expansion-panel-title expand-icon="mdi-chevron-up" collapse-icon="mdi-chevron-down">
                      Amtrak In The News ({{ trainData.trainNewsItems?.length ?? 0 }})
                    </v-expansion-panel-title>
                    <v-expansion-panel-text class="ma-0 pa-0" id="trainNewsExpansionPanelContent">
                      <v-virtual-scroll :items="trainData.trainNewsItems" height="300" width="100%">
                        <template v-slot:default="{ item }">
                          <v-hover>
                            <template v-slot:default="{ isHovering, props }">
                              <v-list-item style="cursor: pointer;" v-bind="props" :active="isHovering"
                                @click="openURLInNewWindow(item.data.link)">
                                <v-list-item-title> {{ prettyDateWithFormat(
                                  new Date(item.published),
                                  'MM-DD-YY hh:mm A') }}
                                </v-list-item-title>
                                <v-list-item-subtitle>{{ item.data.title }}</v-list-item-subtitle>
                                <template v-if="item.data.image" v-slot:prepend>
                                  <v-avatar size="80" rounded="0"
                                    :style="{ 'background-color': (darkThemeSwitch ? 'white' : undefined) }">
                                    <v-img :src="item.data.image"></v-img>
                                  </v-avatar>
                                </template>
                              </v-list-item>
                            </template>
                          </v-hover>
                        </template>
                      </v-virtual-scroll>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </CustomControl>
              <!-- Stats Ticker -->
              <CustomControl v-show="mdAndUp" :position="'BOTTOM_CENTER'">
                <div
                  :style="{ overflow: 'hidden', height: 'auto', width: windowWidth + 'px', color: darkThemeSwitch ? 'white' : 'black', '--ticker-bg-color': darkThemeSwitch ? 'black' : 'white'}">
                  <div class="ticker-wrap pb-6">
                    <div class="ticker">
                      <div class="ticker__item" key="amtrak-engine">
                        <v-img height="20px" width="110px"
                          :src="require(`@/assets/markers/amtrak-profile-selected/amtrak-profile-west.png`)"></v-img>
                      </div>
                      <div class="ticker__item" v-for="(trivia, index) in trainData.statistics" :key="index">
                        <span class="mx-2">{{ trivia }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </CustomControl>
              <!-- Stations -->
              <!-- Test Station-->
              <!-- <template :v-if="trainData?.trainStationsByCode?.DEN">
                <Marker :options="getMarkerOptions({ station: trainData.trainStationsByCode.DEN })">
                <InfoWindow :style="{ 'background-color': 'black', 'border-radius': '1px' }">
                  <v-card height="75" width="200" class="pa-0 ma-0">
                    <v-card-item>
                      <div class="d-flex flex-column justify-center align-center">
                        <v-card-title>DEN</v-card-title>
                        <div class="pr-0 pl-0 mr-0 ml-0">{{ trainData.trainStationsByCode['DEN']?.properties.StationName ?? '' }}</div>
                      </div>
                    </v-card-item>
                  </v-card>
                </InfoWindow>
              </Marker>
              </template> -->
              <Marker v-for="(station, stationCode) in trainData.trainStationsByCode"
                :options="getMarkerOptions({ station })" :key="stationCode">
                <InfoWindow :style="{ 'background-color': 'black' }" :options="{ disableAutoPan: true }"
                  @visible="() => refreshTrainStationInfo(stationCode)">
                  <v-card height="auto" width="auto" class="pa-0 ma-0" :style="{ 'border-radius': '0px' }">
                    <v-card-item>
                      <div class="d-flex flex-column justify-center align-center">
                        <v-card-title>{{ stationCode }}</v-card-title>
                        <div class="pr-0 pl-0 mr-0 ml-0"
                          v-if="!isStringEmpty(trainData.trainStationsByCode[stationCode]?.properties.Name)">{{
                          trainData.trainStationsByCode[stationCode]?.properties.Name }}</div>
                        <div class="pr-0 pl-0 mr-0 ml-0">{{
                          trainData.trainStationsByCode[stationCode]?.properties.StationName ?? '' }}</div>
                        <div class="pr-0 pl-0 mr-0 ml-0">{{
                          trainData.trainStationsByCode[stationCode]?.properties.StaType ?? '' }}</div>
                        <div class="pr-0 pl-0 mr-0 ml-0">Weather: {{
                          trainData.trainStationInfoByCode[stationCode]?.currentWeather ?? '' }}</div>
                        <div class="pr-0 pl-0 mr-0 ml-0">Elevation: {{
                          trainData.trainStationInfoByCode[stationCode]?.elevationFeet ?? '' }}</div>
                      </div>
                    </v-card-item>
                  </v-card>
                </InfoWindow>
              </Marker>
              <!-- Selected Route -->
              <!-- <template v-if="selectedRoute?.[0]">
                <Marker v-for="point in trainData.trainRoutes[selectedRoute[0]]?.points" :key="point"
                  :options="getMarkerOptions({ point })" />
              </template> -->
              <!-- Selected Train Path -->
              <template v-if="selectedTrainForSelect?.[0] && selectedTrain?.path && showSelectedTrainMovementDebugInfo">
                <template v-if="mapZoomSliderValue >= 13.0">
                  <CustomMarker key="selected-train-path-datestamp-start"
                    :options="{ position: selectedTrain.path.slice(0,1).pop(), anchorPoint: 'RIGHT_CENTER', offsetX: -20 }">
                    <v-card height="auto" width="auto" class="pa-0 ma-0" :style="{ 'border-radius': '0px' }">
                      <v-card-item>
                        <div class="d-flex flex-column justify-center align-center">
                          <div class="pr-0 pl-0 mr-0 ml-0">Previous: {{
                            selectedTrain.previousTrainTS }}</div>
                        </div>
                      </v-card-item>
                    </v-card>
                  </CustomMarker>
                  <CustomMarker key="selected-train-path-datestamp-end"
                    :options="{ position: selectedTrain.path.slice(-1).pop(), anchorPoint: 'LEFT_CENTER', offsetX: 20 }">
                    <v-card height="auto" width="auto" class="pa-0 ma-0" :style="{ 'border-radius': '0px' }">
                      <v-card-item>
                        <div class="d-flex flex-column justify-center align-center">
                          <div class="pr-0 pl-0 mr-0 ml-0">Current: {{
                            selectedTrain.data.lastValTrainTS }}</div>
                        </div>
                      </v-card-item>
                    </v-card>
                  </CustomMarker>
                </template>
                <!-- <Marker key="selected-train-path-datestamp-start" :options="getMarkerOptions({ point: { ...selectedTrain.path.slice(0,1).pop(), debugLabel: selectedTrain.previousTrainTS }})" />
                <Marker key="selected-train-path-datestamp-end" :options="getMarkerOptions({ point: { ...selectedTrain.path.slice(-1).pop(), debugLabel: selectedTrain.data.lastValTrainTS }})" /> -->
                <Polyline
                  :options="polylineOptionsWith({ pathPoints: selectedTrain.path, isGeoDesic: false, color: '#005179', opacity: 1.0, weight: 5 })" />
              </template>
              <template
                v-if="selectedTrainForSelect?.[0] && selectedTrainStationSegment && showSelectedTrainStationSegmentTrackInfo">
                <Polyline
                  :options="polylineOptionsWith({ pathPoints: selectedTrainStationSegment, isGeoDesic: false, color: '#33cc33', opacity: 1.0, weight: 5, dashedLineEffect: true })" />
              </template>
              <template
                v-if="selectedCacheViewerSegmentPath && adminKey">
                <Polyline
                  :options="polylineOptionsWith({ pathPoints: selectedCacheViewerSegmentPath, isGeoDesic: false, color: '#1BDDF2', opacity: 1.0, weight: 3, dashedLineEffect: false })" />
              </template>
              <template
                v-if="cacheViewerRenderedPath && adminKey">
                <Polyline
                  :options="polylineOptionsWith({ pathPoints: cacheViewerRenderedPath, isGeoDesic: false, color: '#8354C4', opacity: 1.0, weight: 3, dashedLineEffect: false })" />
              </template>
              <template v-if="selectedTrainForSelect?.[0] && selectedTrain?.approxPath && showSelectedTrainRoutePoints">
                <Polyline
                  :options="polylineOptionsWith({ pathPoints: selectedTrain.approxPath, isGeoDesic: false, color: '#005179', opacity: 0.75, weight: 3, dashedLineEffect: true })" />
              </template>
              <template
                v-if="selectedTrainForSelect?.[0] && selectedTrainStationSegmentPoints && showSelectedTrainStationSegmentTrackPoints">
                <Marker v-for="point in selectedTrainStationSegmentPoints" :key="point"
                  :options="getMarkerOptions({ point })" />
              </template>
              <template
                v-if="showCacheViewerSegmentPoints && selectedCacheViewerSegmentPoints">
                <Marker v-for="point in selectedCacheViewerSegmentPoints" :key="point"
                  :options="getMarkerOptions({ point })" />
              </template>
            </GoogleMap>
            <v-overlay contained :model-value="isLoadingTrainData" class="align-center justify-center">
              <div class="d-flex flex-column align-center justify-center">
                <p>Loading...</p>
                <v-progress-circular indeterminate size="64"></v-progress-circular>
              </div>
            </v-overlay>
          </v-col>
        </v-row>
        <v-row v-if="selectedTrain && showSelectedTrainDebugInfo">
          <v-col cols="12" class="mt-0 pt-0 pb-0 pl-0 pr-0">
            <Codemirror :style="{ 'font-size': '12px' }" :height="185"
              :options="{ value: rawDataStringForTrainData(selectedTrain.data), mode: 'text/javascript', theme: (darkThemeSwitch ? 'dracula' : 'default'), lineNumbers: true, readOnly: true, foldGutter: true, gutters: ['error', 'CodeMirror-linenumbers', 'CodeMirror-foldgutter'] }" />
          </v-col>
        </v-row>
      </v-container>
      <!-- Page Info Dialog -->
      <v-dialog v-model="infoDialog" contained max-width="550px">
        <v-card dark elevation="8">
          <v-toolbar color="rgba(0, 0, 0, 0)" :theme="currentTheme">
            <v-card-title>About Trak Watch</v-card-title>

            <template v-slot:append>
              <p>
                <a href="http://www.mjchp.com/" style="text-decoration:none" target="_blank">
                  <MHPSLogo class="mr-2" :abbreviated="true" :maxFontSize="20" />
                </a>
              </p>
            </template>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>
            <p>This page is NOT affiliated with Amtrak or any government agency. See the official Amtrak website <a
                href="https://www.amtrak.com/" target="_blank" style="text-decoration:none"><strong>here</strong></a>.
            </p>
            <p>Note: All train movement is simulated and all train locations are approximate - based on data from Amtrak
              which is always at least a few minutes old. Always stay alert near railroad tracks and <a
                href="https://oli.org/safety-near-trains/track-safety-basics" target="_blank"
                style="text-decoration:none"><strong>always expect a train</strong></a>.</p>
            <br>
            <div>App Settings:</div>
            <v-form>
              <v-checkbox class="smaller-v-checkbox pl-4 mt-0 pt-0 mb-0 pb-0" hide-details="auto" density="compact"
                v-model="useHighPerformanceMovementRendering" label="High Performance Movement Rendering Mode">
              </v-checkbox>
              <v-checkbox class="smaller-v-checkbox pl-4 mt-0 pt-0 mb-0 pb-0" hide-details="auto" density="compact"
                v-model="showSelectedTrainDebugInfo" label="Show Raw Data (JSON) for Selected Train">
              </v-checkbox>
              <v-checkbox class="smaller-v-checkbox pl-4 mt-0 pt-0 mb-0 pb-0" hide-details="auto" density="compact"
                v-model="showSelectedTrainMovementDebugInfo" label="Highlight Path for Selected Train">
              </v-checkbox>
              <div class="d-flex justify-start">
                <v-checkbox class="smaller-v-checkbox pl-4 mt-0 pt-0 mb-0 pb-0" hide-details="auto" density="compact"
                  v-model="showSelectedTrainStationSegmentTrackInfo"
                  label="Highlight Station Segment Track for Selected Train">
                </v-checkbox>
              </div>
              <v-checkbox class="smaller-v-checkbox pl-4 mt-0 pt-0 mb-0 pb-0" hide-details="auto" density="compact"
                v-model="showSelectedTrainStationSegmentTrackPoints"
                label="Highlight Station Segment Location Points for Selected Train">
              </v-checkbox>
              <v-checkbox class="smaller-v-checkbox pl-4 mt-0 pt-0 mb-0 pb-0" hide-details="auto" density="compact"
                v-model="showSelectedTrainRoutePoints" label="Highlight Approx Route for Selected Train">
              </v-checkbox>
              <v-checkbox v-if="!isProd" class="smaller-v-checkbox pl-4 mt-0 pt-0 mb-0 pb-0" hide-details="auto"
                density="compact" v-model="useProductionHistoricalDataAPI"
                label="Use Production Server Historical Data">
              </v-checkbox>
              <v-text-field
                class="pl-4 pt-1"
                v-model="adminKey"
                :append-inner-icon="showAdminKey ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showAdminKey ? 'text' : 'password'"
                label="Management Key"
                hint="Required for admin features."
                persistent-hint
                density="compact"
                @click:append-inner="showAdminKey = !showAdminKey"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="infoDialog = false">
              Dismiss
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Cockpit Camera -->
      <!-- Cockpit View -->
      <div v-if="mdAndUp && showSelectedTrainCockpitView && selectedTrain">
        <div class="d-flex flex-column align-center justify-center cockpit-camera" ref="cockpitCameraMoveableDiv"
          :style="{
            'min-width': '600px', 'min-height': '364px', 'height': '364px', 'position': 'absolute',
            'max-width': '90vw', 'max-height': '90vh',
            'left': `${(windowWidth / 2) - 300}px`,
            'bottom': '44px',
            'box-shadow': '0 2px 6px rgba(0, 0, 0, 0.3)'
          }">
          <div class="drag-area" style="width: 100%; height: 24px; cursor: move;">
            <v-layout style="height: 100%; width: 100%;">
              <v-system-bar window height="24">
                <span>Train Camera - {{ trainCameraTitleForEnum(cesiumCameraDirectionEnum) }}</span>
                <v-spacer></v-spacer>
                <v-menu
                  v-model="showCockpitCameraMenu"
                  transition="slide-y-transition"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn icon="mdi-video-switch" size="small" variant="text" v-bind="props"></v-btn>
                  </template>
                  <div class="d-flex justify-center align-center" :style="{ width: '475px', 'background-color': 'rgba(62,72,84)' }"> 
                    <p :style="{ 'padding-left': '4px', 'font-size': '12px', color: 'white'}">Camera: </p>
                    <v-btn-toggle class="ml-2 mr-2" v-model="cesiumCameraDirectionEnum" divided dense mandatory
                          @update:modelValue="cockpitCameraDirectionChanged" theme="dark" style="height: auto;">
                          <v-btn size="x-small" height="20" value="left">
                            Left Window
                          </v-btn>

                          <v-btn size="x-small" height="20" value="cockpit">
                            Cab
                          </v-btn>

                          <v-btn size="x-small" height="20" value="right">
                            Right Window
                          </v-btn>
                        </v-btn-toggle>
                    <p :style="{ 'padding-left': '4px', 'font-size': '12px', color: 'white'}">Resolution: {{
                      cesiumResolutionScale }}x</p>
                    <v-slider color="white" v-model="cesiumResolutionScale" :thumb-size="12"
                      @end="cockpitCameraResolutionScaleChanged" hide-details :max="1.0" :min="0.2" :step="0.1"
                      :style="{ 'z-index': 1 }"></v-slider>
                  </div>
                </v-menu>
                <v-menu
                  v-model="showCockpitAudioMenu"
                  transition="slide-y-transition"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn :icon="ambientAudioEnabled && ambientAudioVolume > 0 ? 'mdi-volume-high' : 'mdi-volume-off'" size="small" variant="text" v-bind="props"></v-btn>
                  </template>
                  <div class="d-flex justify-center align-center" :style="{ width: '475px', 'background-color': 'rgba(62,72,84)' }">
                    <p :style="{ 'padding-left': '4px', 'font-size': '12px', color: 'white'}">Ambient Sound:</p>
                    <v-checkbox class="smaller-v-checkbox" hide-details="auto" density="compact" v-model="ambientAudioEnabled" label=""></v-checkbox>
                    <p :style="{ 'padding-left': '4px', 'font-size': '12px', color: 'white'}">Volume:</p>
                    <v-slider color="white" v-model="ambientAudioVolume" :thumb-size="12"
                      @end="ambientAudioVolumeChanged" hide-details :max="1.0" :min="0.0" :step="0.05"
                      :style="{ 'z-index': 1 }"></v-slider>
                  </div>
                </v-menu>
                <v-btn icon="mdi-close" size="small" variant="text" @click="() => showSelectedTrainCockpitView = false"></v-btn>
              </v-system-bar>
            </v-layout>
          </div>
          <VcViewer @ready="cesiumViewerReady" :fullscreenButton="true" :infoBox="false"
            :style="{ 'width': '100%', 'height': 'calc(100% - 24px)' }">
            <vc-layer-imagery>
              <!-- <vc-imagery-provider-bing :bmKey="bingMapsKey" map-style="AerialWithLabels">
                    </vc-imagery-provider-bing> -->
            </vc-layer-imagery>
            <!-- <vc-navigation :zoomOpts="false" :printOpts="false" :locationOpts="false">
                  </vc-navigation> -->
            <!-- <vc-terrain-provider-cesium></vc-terrain-provider-cesium> -->
            <vc-status-bar customClass="cockpit-cam-fps" :showMouseInfo="false" :showCameraInfo="false"></vc-status-bar>
            <!-- Train Cam Info -->
            <div
              :style="{ 'background-color': 'rgba(62,72,84)', width: 'auto', position: 'absolute', top: isTrackingMapHiddenByFullscreenElement() ? '0px' : '25px', left: '0px', 'z-index': 1, 'border-radius': '3px' }">
              <div class="d-flex flex-column justify-center align-center">
                <template v-if="isTrackingMapHiddenByFullscreenElement()">
                  <p :style="{ 'padding-left': '4px', 'font-size': '36px', color: 'white' }">{{
                      selectedTrain.data.routeName
                      }}
                      #{{ selectedTrain.data.trainNum }} ({{ selectedTrain.data.trainState }})</p>
                    <p :style="{ 'padding-left': '4px', 'font-size': '12px', color: 'white' }">Location: {{
                      selectedTrainLocation }}</p>
                      <p v-if="selectedTrain.data.currentStation"
                      :style="{ 'padding-left': '4px', 'font-size': '12px', color: 'white' }">Current Stop: {{
                        selectedTrain.data.currentStation.code }}
                      ({{ selectedTrain.data.currentStation.info.properties.StationName }})
                      (Est. Depart {{ selectedTrain.data.currentStation.estDep
                        ? humanTimeSinceDate(new Date(selectedTrain.data.currentStation.estDep))
                        : 'Unknown' }})
                    </p>
                    <template v-if="selectedTrain.data.nextStation">
                      <p :style="{ 'padding-left': '4px', 'font-size': '12px', color: 'white' }">Next Stop: {{
                        selectedTrain.data.nextStation.code }} ({{
                          selectedTrain.data.nextStation.info?.properties.StationName ?? '----' }}) {{
                          selectedTrain.data.nextStation.estArr ? `(ETA ${humanTimeSinceDate(new
                            Date(selectedTrain.data.nextStation.estArr))})` : '' }}</p>
                    </template>
                    <p :style="{ 'padding-left': '4px', 'font-size': '12px', color: 'white' }">Last Updated: {{
                      humanTimeSinceDate(new Date(selectedTrain.data.lastValTS)) }}
                    </p>
                      <div class="d-flex align-center justify-space-around" style="width: 450px;">
                        <div class="d-flex align-center justify-center">
                        <v-app-bar-nav-icon>
                          <img style="width: 30px; height: 30px;" :src="require('@/assets/Amtrak-Emblem.jpg')" />
                        </v-app-bar-nav-icon>
                        <v-toolbar-title>{{ componentTitle }}</v-toolbar-title>
                        <v-btn size="x-small" variant="plain" disabled>
                            v{{ projectVersion }}
                        </v-btn>
                      </div>
                      <div class="d-flex align-center justify-center">
                        <a href="http://www.mjchp.com/" style="text-decoration:none" target="_blank">
                          <MHPSLogo :abbreviated="true" :maxFontSize="20" />
                        </a>
                      </div>
                      </div>
                </template>
                <template v-else>
                  <div class="d-flex justify-center">
                  <div class="mr-2">
                    <p :style="{ 'padding-left': '4px', 'font-size': '12px', color: 'white' }">{{
                      selectedTrain.data.routeName
                      }}
                      #{{ selectedTrain.data.trainNum }} ({{ selectedTrain.data.trainState }})</p>
                    <p :style="{ 'padding-left': '4px', 'font-size': '12px', color: 'white' }">Location: {{
                      selectedTrainLocation }}</p>
                  </div>
                  <div class="mr-2">
                    <p v-if="selectedTrain.data.currentStation"
                      :style="{ 'padding-left': '4px', 'font-size': '12px', color: 'white' }">Current Stop: {{
                        selectedTrain.data.currentStation.code }}
                      ({{ selectedTrain.data.currentStation.info.properties.StationName }})
                      (Est. Depart {{ selectedTrain.data.currentStation.estDep
                        ? humanTimeSinceDate(new Date(selectedTrain.data.currentStation.estDep))
                        : 'Unknown' }})
                    </p>
                    <template v-if="selectedTrain.data.nextStation">
                      <p :style="{ 'padding-left': '4px', 'font-size': '12px', color: 'white' }">Next Stop: {{
                        selectedTrain.data.nextStation.code }} ({{
                          selectedTrain.data.nextStation.info?.properties.StationName ?? '----' }}) {{
                          selectedTrain.data.nextStation.estArr ? `(ETA ${humanTimeSinceDate(new
                            Date(selectedTrain.data.nextStation.estArr))})` : '' }}</p>
                    </template>
                    <p :style="{ 'padding-left': '4px', 'font-size': '12px', color: 'white' }">Last Updated: {{
                      humanTimeSinceDate(new Date(selectedTrain.data.lastValTS)) }}
                    </p>
                  </div>
                </div>
                </template>
                <audio ref="ambientAudioPlayerRef" loop>
                    <source :src="require('@/assets/audio/ambient.mp3')" type="audio/mp3">
                </audio> 
              </div>
            </div>
            <vc-compass position="bottom-right"></vc-compass>
          </VcViewer>
        </div>
        <Moveable className="moveable" :target="cockpitCameraMoveableDiv" :dragTarget="'.drag-area'" :draggable="true"
          :resizable="true" :keepRatio="false" :throttleResize="1"
          :renderDirections="['nw','n','ne','w','e','sw','s','se']" @resize="onCockpitCameraResize" :scalable="false"
          :rotatable="false" :origin="false" :rootContainer="document.body" :snappable="true"
          :bounds="{'left':0,'top':0,'right':0,'bottom':0, 'position': 'css'}" :hideDefaultLines="true"
          @drag="onCockpitCameraDrag" @scale="onCockpitCameraScale" />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, inject, reactive, ref, watch } from 'vue'
import { useDisplay } from 'vuetify'
// CesiumJS
import { VcViewer } from 'vue-cesium'
import { moveCesiumCamera, updateCesiumCameraOrientation, clampedHeightAtPoint, setCesiumViewerProps, getCesiumViewerProps } from './utils/cesiumUtils.js'
// CodeMirror (JSON code editor)
import Codemirror from "codemirror-editor-vue3"
import "codemirror/mode/javascript/javascript.js"
import "codemirror/addon/display/placeholder.js"
import "codemirror/addon/fold/brace-fold.js"
import "codemirror/addon/fold/foldcode.js"
import "codemirror/addon/fold/foldgutter.js"
import "codemirror/addon/fold/foldgutter.css"
import "codemirror/theme/dracula.css"
import { useQueryClient, queryOptions } from '@tanstack/vue-query'
import {
  getTrainDataWithTransitions,
  /* getHistoricalRouteData, */
  /* getTrainMovements, */
  getWeatherDetailsForLocation,
  getTrainNews,
  getTrainHistory,
  getRouteSampleMovement,
  getStationSegment,
  setStationSegment,
  getSegmentsForRoute,
  getStationSegmentPoints,
  getTrainsForSegment,
  getRouteForTrain,
  getRoutes
} from './utils/dataUtils.js'
import { renderStatisticsTrivia, appendServiceDisruptionInfoToTrivia } from './utils/statsUtils.js'
import { GoogleMap, Marker, InfoWindow, CustomControl, CustomMarker, Polyline } from 'vue3-google-map'
import { humanTimeSinceDate, prettyDateWithFormat, isStringEmpty } from './utils/uiUtils.js'
// import { pathContains } from './utils/geoUtils.js'
import { positionAndHeadingAlongPathWith } from './utils/geoUtils.js'
import { pathFromStationSegmentPath } from './utils/workerUtils.js'
import { polylineOptionsWith, googleMapDarkThemeStyles, elevationInFeetForCoords } from './utils/googleMapsUtils.js'
import {
  getTrainHeadingForData,
  getHeadingStringForHeading,
  trainItemLabelForData,
  trainItemShortLabelForData,
  trainCoordsForData,
  trainLastStationCodeForData,
  trainLastStationCoordsForData,
  trainNextStationCodeForData,
  trainNextStationCoordsForData,
  rawDataStringForTrainData,
  trainSourceAndDestinationStationsListForSegment,
  trainTSForData,
  fetchAndRenderTrainStationSegment
} from './utils/trainDataUtils.js'
import MHPSLogo from './components/logos/MHPSLogo.vue'
import { checkForRouteSampleInCache, getRouteSampleFromCache, insertRouteSampleInCache } from './utils/pathCache.js'
import { useDocumentVisibility, useFullscreen } from '@vueuse/core'
import { getGPUTier } from 'detect-gpu'
import Moveable from "vue3-moveable"
import { geoJSONDataFromPath, pathFromGeoJSONLineString } from './utils/exportUtils.js'
import promiseAllProperties from 'promise-all-properties'

const cacheViewerRouteAny = 'Any Route'

export default {
  name: 'App',
  head() {
    return {
    // Children can override the title.
    title: `Trak Watch v${process.env.PACKAGE_VERSION}`,
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    // titleTemplate: '%s ← My Site',
    // Define meta tags here.
    meta: [
      {name: 'description', content: 'An almost-live Amtrak ride-a-long experience.'},
      // OpenGraph data (Most widely used)
      {property: 'og:title', content: 'An almost-live Amtrak ride-a-long experience.'},
      {property: 'og:site_name', content: 'An almost-live Amtrak ride-a-long experience.'},
      // The list of types is available here: http://ogp.me/#types
      {property: 'og:type', content: 'website'},
      // Should the the same as your canonical link, see below.
      {property: 'og:url', content: 'https://www.trak.watch/'},
      {property: 'og:image', content: 'https://www.trak.watch/favicon.png'},
      // Often the same as your meta description, but not always.
      {property: 'og:description', content: 'Description of SPA'},
      // Google / Schema.org markup:
      {itemprop: 'name', content: `Trak Watch v${process.env.PACKAGE_VERSION}`},
      {itemprop: 'description', content: 'An almost-live Amtrak ride-a-long experience.'},
      {itemprop: 'image', content: 'https://www.trak.watch/favicon.png'}
    ],
    link: [
      {rel: 'canonical', href: 'https://www.trak.watch/'}
    ]
  }
  },
  components: {
    // eslint-disable-next-line
    Codemirror, VcViewer, GoogleMap, Marker, InfoWindow, CustomControl, CustomMarker, Polyline, MHPSLogo, Moveable
  },
  setup() {
    // Logger
    const logger = inject('vuejs3-logger')

    // App Constants
    const componentTitle = ref('Trak Watch')
    const projectVersion = ref(process.env.PACKAGE_VERSION || '0')

    // Tanstack Query Client Helpers
    const stationSegmentQueryClient = useQueryClient()

    // Station Segment Cache
    const stationSegmentCacheKey = ({ route, origin, destination, useProd }) => {
      return ['stationSegments', route, origin, destination, useProd]
    }
    const stationSegmentQueryOptions = ({ train, route, origin, destination, useProd }) => {
      return queryOptions({
        queryKey: stationSegmentCacheKey({ route, origin, destination, useProd }),
        queryFn: () => getStationSegment({ train, route, origin, destination, useProd }),
        staleTime: 60 * 60 * 1000, // 1 hour TTL
      })
    }
    const cachedGetStationSegment = async ({ train, route, origin, destination, useProd = false }) => {
      return await stationSegmentQueryClient.ensureQueryData(stationSegmentQueryOptions({ train, route, origin, destination, useProd }))
    }

    // Station Segment Points Cache
    const stationSegmentPointsCacheKey = ({ route, train, previousStationCodes, destStationCodes, maxResults, useProd }) => {
      return ['stationSegmentPoints', route, train, previousStationCodes.join(','), destStationCodes.join(','), maxResults, useProd]
    }
    const stationSegmentPointsQueryOptions = ({ train, route, previousStationCodes, destStationCodes, maxResults, useProd }) => {
      return queryOptions({
        queryKey: stationSegmentPointsCacheKey({ train, route, previousStationCodes, destStationCodes, maxResults, useProd }),
        queryFn: () => getStationSegmentPoints({ 
          train,
          route,
          previousStationCodes,
          destStationCodes,
          maxResults,
          useProd
        }),
        staleTime: 10 * 60 * 1000, // 10 mins TTL
      })
    }
    const cachedGetStationSegmentPoints = async ({ train, route, previousStationCodes, destStationCodes, maxResults, useProd = false }) => {
      return await stationSegmentQueryClient.ensureQueryData(stationSegmentPointsQueryOptions({ train, route, previousStationCodes, destStationCodes, maxResults, useProd }))
    }

    // Data refetch/refresh
    const dataRefreshPeriodMS = 180000
    const dataRefreshErrorOrEmptyPeriodMS = 10000
    const shouldRefresh = ref(true)

    // Train Data
    const isProdVal = process.env.VUE_APP_ORIGIN?.startsWith('https://')
    const isProd = ref(isProdVal)
    const isLoadingTrainData = ref(false)
    const trainData = reactive({
      trainRoutes: {}, // Route Items for Train List
      trainItemLabels: {}, // Items for Train list
      trainItemsByLabel: {}, // Includes train data + metadata for visualizations 
      trainStationsByCode: {}, // All stations keyed by station code
      trainStationInfoByCode: {}, // Dynamic station info (elevation, weather)
      trainNewsItems: [], // Recent train news articles
      statistics: [], // Ticker Trivia and Stats
      trainsSHA256: null,
      stationsSHA256: null,
      serviceDisruptions: [] // Trains with service disruptions
    })

    const selectedTrainStationSegment = ref([]) // station segment path for hte selected train
    const selectedTrainStationSegmentPoints = ref([]) // station segment path points for the selected train

    const trainStationSegmentsById = reactive({}) // train segments for rendering

    // Settings
    const showSelectedTrainDebugInfo = ref(false)
    const showSelectedTrainMovementDebugInfo = ref(false)
    const showSelectedTrainStationSegmentTrackInfo = ref(false)
    const showSelectedTrainStationSegmentTrackPoints = ref(false)
    const showSelectedTrainCockpitView = ref(false)
    const showSelectedTrainRoutePoints = ref(false)
    const pathFinderMaxTrackCurvatureDegrees = ref(10)
    const pathFinderMaxTrackCurvatureRangeFeet = ref(400)
    const pathFinderMaxTrackCurvatureSkip = ref(10)
    const pathFinderSegmentPointsMax = ref(20000)
    const pathFinderHintFailsafeThresholdRatio = ref(2.5)
    const useProductionHistoricalDataAPI = ref(!isProdVal)
    const adminKey = ref('')
    const showAdminKey = ref(false)

    // Shared Tracking Map Data
    const defaultTrackingMapLocation = ref({ lat: 39.8333, lng: -98.585522 })
    
    // Cesium Map Data
    const bingMapsKey = process.env.VUE_APP_BING_MAPS_KEY
    const cesiumViewer = ref(null)
    const cesiumLib = ref(null)
    const defaultCameraHeight = ref(1000) // "The height, in meters, above the ellipsoid." from: https://stackoverflow.com/questions/38709743/cesiums-height-value-is-altitude-or-above-sea-level
    const defaultCameraTerrainHeightOffset = ref(4) // meters
    const cesiumResolutionScale = ref(1) // Render resolution scale for cockpit view
    const cesiumCameraDirectionEnum = ref('cockpit') // Camera direction enum selection
    const ambientAudioPlayerRef = ref(null) // Pointer to audio element
    const ambientAudioEnabled = ref(false) // Play ambient train audio while camera is enabled
    const ambientAudioVolume = ref(1.0) // Ambient train audio volume level
    const showCockpitCameraMenu = ref(false) // model for cockpit camera settings
    const showCockpitAudioMenu = ref(false) // model for cockpit camera audio settings

    // Cesium Map Helpers
    const cesiumViewerReady = async ({ Cesium, viewer }) => {
      logger.debug(`Cesium viewer is ready!`)
      // Keep references to the Cesium instances
      cesiumLib.value = Cesium
      cesiumViewer.value = viewer

      viewer.scene.globe.show = false;
      viewer.skyAtmosphere = new Cesium.SkyAtmosphere()

      // Resolution and Rendering Settings
      viewer.resolutionScale = cesiumResolutionScale.value
      viewer.scene.globe.tileCacheSize = 1000 // default 100
      viewer.scene.requestRenderMode = true

      // Map Elements
      viewer.scene.screenSpaceCameraController.enableZoom = false
      viewer.scene.screenSpaceCameraController.enableTranslate = false
      viewer.scene.screenSpaceCameraController.enableLook = false
      viewer.scene.screenSpaceCameraController.lookEventTypes = []
      //viewer.sceneModePicker = false
      //viewer.baseLayerPicker = false
      
      // Bing road maps / labels
      // try {
      //   viewer.imageryLayers.addImageryProvider(
      //     await Cesium.IonImageryProvider.fromAssetId(4)
      //   )
      // } catch (error) {
      //   console.log(error)
      // }

      // Google 3D Photorealistic Tiles
      try {
        const tileset = await Cesium.Cesium3DTileset.fromIonAssetId(2275207)
        // tileset.style = new Cesium.Cesium3DTileStyle({
        //   color : 'rgba(255, 255, 255, 0.5)'
        // });
        viewer.scene.primitives.add(tileset)
      } catch (error) {
        console.log(error)
      }
      // Add the 3d buildings tile set
      // try {
      //   const tileset = await Cesium.Cesium3DTileset.fromIonAssetId(96188)
      //   viewer.scene.primitives.add(tileset)
      //   // // Apply the default style if it exists
      //   // var extras = tileset.asset.extras
      //   // if (
      //   //   Cesium.defined(extras) &&
      //   //   Cesium.defined(extras.ion) &&
      //   //   Cesium.defined(extras.ion.defaultStyle)
      //   // ) {
      //   //   tileset.style = new Cesium.Cesium3DTileStyle(extras.ion.defaultStyle)
      //   // }
      // } catch (error) {
      //   console.log(error)
      // }

      // If there's a selected train, initialize the camera position
      if (selectedTrain.value) {
        const trainCoords = trainCoordsForData(selectedTrain.value.data)
        clampedHeightAtPoint({ cesiumLib: cesiumLib.value, cesiumViewer: cesiumViewer.value, lat: trainCoords.lat, lng: trainCoords.lng }).then((height) => {
          moveCesiumCamera({ cesiumLib: cesiumLib.value, cesiumViewer: cesiumViewer.value, location: trainCoords, heading: trainCameraHeadingForTrainHeading(selectedTrain.value.data.headingDegrees), pitch: 0, height: height + defaultCameraTerrainHeightOffset.value })
        })
      } else {
        // Go to the 'Center of US' long/lat
        moveCesiumCamera({
          cesiumLib: cesiumLib.value,
          cesiumViewer: cesiumViewer.value,
          location: defaultTrackingMapLocation.value,
          height: defaultCameraHeight.value,
          heading: 0,
          pitch: -90,
          roll: 0
        })
      }
    }
    const onEntityEvt = (e) => {
      logger.debug(`Entity event type: ${e.type}`)
    }

    // Cesium Moveable Helpers
    const cockpitCameraMoveableDiv = ref(null)
    const cockpitCameraMoveableContainer = ref(null)
    const onCockpitCameraDrag = (e) => {
      e.target.style.transform = e.transform
    }

    const onCockpitCameraScale = (e) => {
      e.target.style.transform = e.transform
    }

    const onCockpitCameraResize = (e) => {
        e.target.style.width = `${e.width}px`
        e.target.style.height = `${e.height}px`
        e.target.style.transform = e.drag.transform
    }

    // Cesium Camera Helpers
    const trainCameraHeadingForTrainHeading = (trainHeadingDegrees = 90) => {
      // Adjustment for camera direction setting
      let cameraHeadingDegrees = trainHeadingDegrees
      if (cesiumCameraDirectionEnum.value == 'left') {
        cameraHeadingDegrees -= 90
      } else if (cesiumCameraDirectionEnum.value == 'right') {
        cameraHeadingDegrees += 90
      }

      // Normalize range to [0, 360>
      if (cameraHeadingDegrees >= 360) {
        cameraHeadingDegrees -= 360
      } else if (cameraHeadingDegrees < 0) {
        cameraHeadingDegrees += 360
      }
      return cameraHeadingDegrees
    }

    const trainCameraTitleForEnum = (cameraViewEnum) => {
      if (cameraViewEnum === 'left') {
        return 'Left Window'
      } else if (cameraViewEnum === 'right') {
        return 'Right Window'
      }
      return 'Cab View'
    }

    const cockpitCameraResolutionScaleChanged = (val) => {
      setCesiumViewerProps({ cesiumLib: cesiumLib.value, cesiumViewer: cesiumViewer.value, resolutionScale: val })
      logger.debug(`Cockpit resolution scale changed to ${JSON.stringify(val)}`)
    }

    const cockpitCameraDirectionChanged = (val) => {
      // If there's a selected train, reset the camera position
      if (selectedTrain.value) {
        const trainCoords = trainCoordsForData(selectedTrain.value.data)
        clampedHeightAtPoint({ cesiumLib: cesiumLib.value, cesiumViewer: cesiumViewer.value, lat: trainCoords.lat, lng: trainCoords.lng }).then((height) => {
          updateCesiumCameraOrientation({ cesiumLib: cesiumLib.value, cesiumViewer: cesiumViewer.value, pitch: 0, height: height + defaultCameraTerrainHeightOffset.value, heading: trainCameraHeadingForTrainHeading(selectedTrain.value.data.headingDegrees) })
        })
        
      }
      logger.debug(`Cockpit camera direction changed to ${val}`)
    }

    const ambientAudioVolumeChanged = (/* val */) => {
      // if (ambientAudioPlayerRef.value) {
      //   ambientAudioPlayerRef.value.volume = val
      // }
    }

    // manage ambient audio
    watch([ambientAudioPlayerRef, ambientAudioEnabled, ambientAudioVolume], ([newAudioPlayerRef, newEnabledVal, newVolumeVal]) => {
      if (newAudioPlayerRef) {
        // Audio player is enabled
        if (newEnabledVal) {
          // Make sure audio is playing and volume is set appropriately
          newAudioPlayerRef.volume = newVolumeVal
          newAudioPlayerRef.play()
        } else {
          // Stop audio
          newAudioPlayerRef.pause()
        }
      }
    })

    // Tracking Map Data
    const minTrackingMapZoom = 4.908878105927477 // USA fits in window (and then some)
    const maxTrackingMapZoom = 20
    const defaultTrackingMapTrainFocusZoom = 12 // shows train w/ good 'nearest-city' context
    const defaultTrackingMapChaseModeZoom = 16 // shows train movement local context
    const defaultTrackingMapTrainClickedFocusZoom = 6.014302856445311
    const googleAPIKey = process.env.VUE_APP_GOOGLE_API_KEY
    const trainMapId = process.env.VUE_APP_TRAIN_MAP_ID
    const trackingMap = ref(null)
    const trackingMapZoom = ref(minTrackingMapZoom)
    const trackingMapTilt = ref(75)
    const trackingMapHeading = ref(0)
    const trackingMapOptions = reactive({
      mapType: 'hybrid',
      mapTypeOptions: {
        mapTypeIds: ['roadmap', 'hybrid', 'terrain', 'satellite'],
        style: 'DROPDOWN_MENU',
        position: 0
      }
    })
    const mapDarkTheme = reactive({
      styles: googleMapDarkThemeStyles
    })
    const selectedTrainElevation = ref(null)
    const selectedTrainWeather = ref(null)
    const selectedTrainLocation = ref(null)
    const followSelectedTrain = ref(false)
    const chaseSelectedTrain = ref(false)
    const expansionPanelState = ref(['selectedTrainDetails'])
    const genralInfoExpansionPanelState = ref(['trainNewsItems'])
    const statsTickerExpansionPanelState = ref(['statsTicker'])
    const cacheViewerExpansionPanelState = ref([])
    const selectedMapType = ref('hybrid') // same as default in mapOptions (above)
    const mapZoomSliderValue = ref(minTrackingMapZoom)
    const mapZoomSliderMin = ref(minTrackingMapZoom)
    const mapZoomSliderMax = ref(maxTrackingMapZoom)
    const mapHeadingSliderValue = ref(0)
    const mapHeadingSliderMin = ref(0)
    const mapHeadingSliderMax = ref(360)
    const mapTiltSliderValue = ref(75)
    const mapTiltSliderMin = ref(0)
    const mapTiltSliderMax = ref(75)
    // const mapTypes = ref(['Roadmap', 'Roadmap w/ Terrain', 'Satellite', 'Hybrid'])
    // Tracking Map Helpers
    // Watch Zoom, Tilt, Heading level
    const getCurrentMapHeading = () => {
      return trackingMap.value?.map?.getHeading() ?? 0
    }
    const getCurrentMapZoom = () => {
      return trackingMap.value?.map?.getZoom() ?? minTrackingMapZoom
    }

    const trackingMapZoomChanged = () => {
      const curZoom = getCurrentMapZoom()

      logger.debug(`Tracking map zoom is now: ${curZoom}.`)
      // Enforce reasonable zoom bounds
      if (curZoom > maxTrackingMapZoom) {
        setTimeout(() => { 
          trackingMap.value?.map.setZoom(maxTrackingMapZoom)
          logger.debug(`Reset Tracking map zoom to max: ${maxTrackingMapZoom}.`)
          mapZoomSliderValue.value = maxTrackingMapZoom
          trackingMapZoom.value = maxTrackingMapZoom
        }, 30)
      } else if (curZoom < minTrackingMapZoom) {
        setTimeout(() => { 
          trackingMap.value?.map.setZoom(minTrackingMapZoom)
          logger.debug(`Reset Tracking map zoom to min: ${minTrackingMapZoom}.`)
          mapZoomSliderValue.value = minTrackingMapZoom
          trackingMapZoom.value = minTrackingMapZoom
        }, 30)
      } else {
        mapZoomSliderValue.value = curZoom
        trackingMapZoom.value = curZoom
      }

      // Update train markers
      updateTrainMarkerIcons()
      updateStationMarkerIcons()
    }
    const trackingMapTiltChanged = () => {
      const curTilt = trackingMap.value?.map.getTilt() ?? 0
      // const maxTilt = 13
      // const minTilt = 2

      logger.debug(`Tracking map tilt is now: ${curTilt}.`)
      // // Enforce reasonable zoom bounds
      // if (curZoom > maxZoom) {
      //   setTimeout(() => { 
      //     trackingMap.value?.map.setZoom(maxZoom)
      //     logger.debug(`Reset Tracking map zoom to max: ${maxZoom}.`)
      //   }, 30)
      // } else if (curZoom < minZoom) {
      //   setTimeout(() => { 
      //     trackingMap.value?.map.setZoom(minZoom)
      //     logger.debug(`Reset Tracking map zoom to min: ${minZoom}.`)
      //   }, 30)
      // }
      mapTiltSliderValue.value = curTilt
      trackingMapTilt.value = curTilt
      // Update train markers
      updateTrainMarkerIcons()
    }
    const trackingMapHeadingChanged = () => {
      const curHeading = getCurrentMapHeading()
      // const maxHeading = 13
      // const minHeading = 2

      logger.debug(`Tracking map heading is now: ${curHeading}.`)
      // // Enforce reasonable zoom bounds
      // if (curZoom > maxZoom) {
      //   setTimeout(() => { 
      //     trackingMap.value?.map.setZoom(maxZoom)
      //     logger.debug(`Reset Tracking map zoom to max: ${maxZoom}.`)
      //   }, 30)
      // } else if (curZoom < minZoom) {
      //   setTimeout(() => { 
      //     trackingMap.value?.map.setZoom(minZoom)
      //     logger.debug(`Reset Tracking map zoom to min: ${minZoom}.`)
      //   }, 30)
      // }

      mapHeadingSliderValue.value = curHeading
      trackingMapHeading.value = curHeading
      // Update train markers
      updateTrainMarkerIcons()
    }
    const trackingMapTypeChanged = () => {
      const curMapTypeId = trackingMap.value?.map.getMapTypeId() ?? "hybrid"
      logger.debug(`Tracking map type id is now: ${curMapTypeId}.`)
      selectedMapType.value = curMapTypeId
      trackingMapOptions.mapType = curMapTypeId
    }

    const initializeTrackingMapConfig = () => {
      // Zoom
      trackingMap.value?.map.setZoom(trackingMapZoom.value)
      // Heading
      trackingMap.value?.map.setHeading(trackingMapHeading.value)
      // Tilt
      trackingMap.value?.map.setTilt(trackingMapTilt.value)
      // Center
      trackingMap.value?.map.setCenter({ lat: 39.8333, lng: -98.585522 }) // US Geographical Center in KS
    }

    let trackingMapIsInitialized = false
    const trackingMapIdleEvent = () => {
      // Tracking map is idle
      if (!trackingMapIsInitialized) {
        trackingMapIsInitialized = true
        initializeTrackingMapConfig()
        logger.debug(`Tracking Map has been initialized!`)
      }
    }

    // Mouse Events
    const mapDragStarted = () => {
      logger.debug(`User started map drag...`)
      // Disable following train
      followSelectedTrain.value = false
      chaseSelectedTrain.value = false
    }

    // Map Type
    const setMapType = (mapTypeEnum) => {
      trackingMap.value?.map.setMapTypeId(mapTypeEnum)
    }

    // Map Fullscreen controls
    const appWindow = ref(null)
    const { /* isFullscreen, */ enter, /* exit, toggle */ } = useFullscreen(appWindow)
    const activateMapFullscreen = async () => {
      await enter()
    }

    const isTrackingMapHiddenByFullscreenElement = () => {
      let isHidden = false
      // Check if an element is in fullscreen mode
      if (document.fullscreenElement) {
        // Check if fullscreen element is the main app window (className contains 'v-application')
        if (document.fullscreenElement.className?.includes('v-application')) {
          // the main app window is currently in fullscreen mode
        } else {
          // Something other than the main app window is in fullscreen mode (e.g. train camera)
          // Assume tracking map is hidden from view
          isHidden = true
        }
      }
      return isHidden
    }

    // Marker Data

    const scaleIconForZoom = (icon) => {
      if (icon) {
        // Compute icon sizing based on map zoom level
        const curZoom = getCurrentMapZoom()
        const minIconScaleFactor = 0.1 // 10% of native size
        const maxIconScaleFactor = 1 // 100% of native size
        const maxZoom = maxTrackingMapZoom
        const minZoom = minTrackingMapZoom

        // Use a linear scale transform (for now) (i.e. y = mx + b )
        const iconScaleSlope = (maxIconScaleFactor - minIconScaleFactor) / (maxZoom - minZoom)
        const iconScaleFactor = minIconScaleFactor + ( curZoom - minIconScaleFactor ) * iconScaleSlope

        icon.scaledSize.width *= iconScaleFactor
        icon.scaledSize.height *= iconScaleFactor
        icon.anchor.x *= iconScaleFactor
        icon.anchor.y *= iconScaleFactor
      }
      return icon
    }

    const getMarkerIcon = ({ station: station = null, train: train = null, point: point = null } = {}) => {
      // NOTE: Map style is set in cloud / always use light theme for now
      const useDark = false // darkThemeSwitch.value
      
      // Default location marker data
      const ico = {
        url: useDark ? require('@/assets/markers/circle-small-dark.png') : require('@/assets/markers/circle-small-light.png'),
        scaledSize: { width: 24, height: 24 },
        anchor: { x: 12, y: 12 }
      }
      if (train) {
        const mapHeading = getCurrentMapHeading()
        const trainHeading = getTrainHeadingForData(train.data)
        const trainIsSelected = isTrainDatumSelected(train.data)
        const trainMarkerFolder = trainIsSelected ? 'amtrak-profile-selected' : 'amtrak-profile-not-selected'
        let absoluteHeading = trainHeading - mapHeading
        if (absoluteHeading < 0) absoluteHeading += 360
        const shouldLimitTrainHeadingForMapTilt = mapTiltSliderValue.value > 45.0 // tracking map tilt is greater than 135 (90 + 45) degrees
        const absoluteHeadingString = getHeadingStringForHeading(absoluteHeading, shouldLimitTrainHeadingForMapTilt)
        
        if (absoluteHeadingString === 'W') {
          ico.url = useDark ? require(`@/assets/markers/${trainMarkerFolder}/amtrak-profile-west.png`) : require(`@/assets/markers/${trainMarkerFolder}/amtrak-profile-west.png`)
          ico.scaledSize = { width: 186, height: 36 }
          ico.anchor = { x: 0, y: 36 }
        } else if (absoluteHeadingString === 'N') {
          ico.url = useDark ? require(`@/assets/markers/${trainMarkerFolder}/amtrak-profile-north.png`) : require(`@/assets/markers/${trainMarkerFolder}/amtrak-profile-north.png`)
          ico.scaledSize = { width: 27, height: 36 }
          ico.anchor = { x: 13, y: 36 }
        } else if (absoluteHeadingString === 'S') {
          ico.url = useDark ? require(`@/assets/markers/${trainMarkerFolder}/amtrak-profile-south.png`) : require(`@/assets/markers/${trainMarkerFolder}/amtrak-profile-south.png`)
          ico.scaledSize = { width: 27, height: 36 }
          ico.anchor = { x: 13, y: 36 }
        } else if (absoluteHeadingString === 'NE') {
          ico.url = useDark ? require(`@/assets/markers/${trainMarkerFolder}/amtrak-profile-north-east.png`) : require(`@/assets/markers/${trainMarkerFolder}/amtrak-profile-north-east.png`)
          ico.scaledSize = { width: 150, height: 141 }
          ico.anchor = { x: 150, y: 0 }
        } else if (absoluteHeadingString === 'NW') {
          ico.url = useDark ? require(`@/assets/markers/${trainMarkerFolder}/amtrak-profile-north-west.png`) : require(`@/assets/markers/${trainMarkerFolder}/amtrak-profile-north-west.png`)
          ico.scaledSize = { width: 150, height: 141 }
          ico.anchor = { x: 0, y: 0 }
        } else if (absoluteHeadingString === 'SW') {
          ico.url = useDark ? require(`@/assets/markers/${trainMarkerFolder}/amtrak-profile-south-west.png`) : require(`@/assets/markers/${trainMarkerFolder}/amtrak-profile-south-west.png`)
          ico.scaledSize = { width: 143, height: 151 }
          ico.anchor = { x: 0, y: 151 }
        } else if (absoluteHeadingString === 'SE') {
          ico.url = useDark ? require(`@/assets/markers/${trainMarkerFolder}/amtrak-profile-south-east.png`) : require(`@/assets/markers/${trainMarkerFolder}/amtrak-profile-south-east.png`)
          ico.scaledSize = { width: 143, height: 151 }
          ico.anchor = { x: 143, y: 151 }
        } else { // 'E'
          ico.url = useDark ? require(`@/assets/markers/${trainMarkerFolder}/amtrak-profile-east.png`) : require(`@/assets/markers/${trainMarkerFolder}/amtrak-profile-east.png`)
          ico.scaledSize = { width: 186, height: 36 }
          ico.anchor = { x: 186, y: 36 }
        }
      } else if (station) {
        ico.url = useDark ? require('@/assets/markers/station-marker.png') : require('@/assets/markers/station-marker.png')
        ico.scaledSize = { width: 32, height: 46 }
        ico.anchor = { x: 16, y: 46 }
      } else if (point) {
        // Use default
      }
      return scaleIconForZoom(ico)
    }

    const getMarkerOptions = ({ station: station = null, train: train = null, point = null } = {}) => {
      let coords
      if (station) {
        coords = coordsForStationData(station)
      } else if (train) {
        coords = train.previousCoords ?? trainCoordsForData(train.data)
      } else {
        coords = point
      }
      return {
        position: coords,
        clickable: true, // Infobox for stations
        draggable: false,
        label: point?.debugLabel ?? null,
        // zIndex: index,
        icon: getMarkerIcon({ station, train, point }),
        // animation: station ? null : trackingMap.value?.api?.Animation.BOUNCE ?? null
        optimized: false // See here: https://stackoverflow.com/a/70459845/2333933
      }
    }

    const panTrackingMap = (coords) => {
      //logger.debug(`Panning to selected train location: ${JSON.stringify(coords)}`)
      trackingMap.value?.map.panTo(coords)

      // moveCesiumCamera({
      //   cesiumLib: cesiumLib.value,
      //   cesiumViewer: cesiumViewer.value,
      //   location: coords,
      //   height: 1000,
      //   pitch: 0
      // })
      
    }

    const orientTrackingMapToHeading = (newHeading) => {
      // Set tracking map heading
      trackingMap.value?.map.setHeading(newHeading)
    }

    const setTrackingMapTilt = (newTilt) => {
      trackingMap.value?.map.setTilt(newTilt)
    }

    const zoomTrackingMap = (zoomLevel) => {
      trackingMap.value?.map.setZoom(zoomLevel)
    }

    const mapTypeSelectEvent = (mapType) => {
      logger.debug(`Map Type Selected: ${mapType}`)
      selectedMapType.value = mapType
      setMapType(mapType)
    }

    const mapZoomSliderUpdated = (val) => {
      // Disable 'chase mode' if user's desired zoom level is beyond default 'chase mode' level
      if (val < defaultTrackingMapChaseModeZoom) {
        chaseSelectedTrain.value = false
      }
      zoomTrackingMap(val)
    }
    const mapHeadingSliderUpdated = (val) => {
      // Disable 'chase mode' to avoid conflicting with user's desired orientation
      chaseSelectedTrain.value = false
      orientTrackingMapToHeading(val)
    }
    const mapTiltSliderUpdated = (val) => {
      // Disable 'chase mode' to avoid conflicting with user's desired orientation
      chaseSelectedTrain.value = false
      setTrackingMapTilt(val)
    }

    const updateSelectedTrainMetadata = async (coords) => {
      // Dispatch elevation request
      if (trackingMap.value) {
        selectedTrainElevation.value = await elevationInFeetForCoords({ 
          mapObject: trackingMap.value,
          coords,
          logger
        })
      }

      // Weather and location info
      const weatherInfo = await getWeatherDetailsForLocation(coords)
      selectedTrainWeather.value = weatherInfo?.weatherDescription ?? ''
      selectedTrainLocation.value = weatherInfo?.locationDescription ?? ''
    }

    // true if the train data corresponds with the selected train
    const isTrainDatumSelected = (data) => {
      let isSelected = false
      if (selectedTrainForSelect.value?.[0] && data) {
        const trainLabel = trainItemLabelForData(data)
        const trainTitle = trainData.trainItemLabels[trainLabel]?.title
        if (trainTitle && selectedTrainForSelect.value[0] == trainTitle) {
          isSelected = true
        }
      }
      return isSelected
    }

    const trainSelected = ({ label: trainLabel, panTo: panTo = false }) => {
      logger.debug(`Train selected: ${trainLabel}`)
      const trainItem = trainData.trainItemsByLabel[trainLabel]
      const trainLabelItem = trainData.trainItemLabels[trainLabel]
      // Make sure data exists
      if (trainItem?.data) {
        // Update selection
        routeSelectEvent(trainItem.data.routeName)
        selectedTrainForSelect.value = [trainLabelItem.title]

        updateSelectedTrainMetadata(trainCoordsForData(trainItem.data))
        // Open details view
        expansionPanelState.value = ['selectedTrainDetails']

        // Default to following
        followSelectedTrain.value = true

        // Reset map center
        //fitMapToCoords([selectedTrainCoords.value, ...newStationCoords])
        const trainCoords = trainCoordsForData(trainItem.data)
        if (panTo) {
          panTrackingMap(trainCoords)
          zoomTrackingMap(defaultTrackingMapTrainFocusZoom)
        }

        // Move camera to selected train's cockpit
        clampedHeightAtPoint({ cesiumLib: cesiumLib.value, cesiumViewer: cesiumViewer.value, lat: trainCoords.lat, lng: trainCoords.lng }).then((height) => {
          moveCesiumCamera({ cesiumLib: cesiumLib.value, cesiumViewer: cesiumViewer.value, location: trainCoords, heading: trainCameraHeadingForTrainHeading(trainItem.data.headingDegrees), pitch: 0, height: height + defaultCameraTerrainHeightOffset.value })
        })

        // Refresh Train Path Info
        // TODO: How can we refresh segment here without interfering with pending updates (w/ correct next station codes)
        // refreshStationSegmentPaths({ trainLabels: [trainLabel] })
        refreshTrainApproxPaths({ trainLabels: [trainLabel] })
        setupSelectedTrainStationSegmentAndPoints({ trainLabel })
      }

      // force refresh of markers
      updateTrainMarkerIcons()
      updateStationMarkerIcons()
    }

    const setupMarkerForTrain = (label, data) => {
      let theMarker = null
      if (trackingMap.value) {
        theMarker = new trackingMap.value.api.Marker(getMarkerOptions({ train: { data } }))
        trackingMap.value.api.event.addListener(theMarker, 'click', (event) => {
          trainSelected({ label })
          // Zoom/Pan in to default zoom level if currently zoomed wide (e.g. default)
          if (getCurrentMapZoom() < defaultTrackingMapTrainClickedFocusZoom) {
            zoomTrackingMap(defaultTrackingMapTrainClickedFocusZoom)
            panTrackingMap(trainCoordsForData(trainData.trainItemsByLabel[label].data))
          }
          logger.debug(`Train '${label}' marker clicked: ${JSON.stringify(event)}`)
        })
        theMarker.setMap(trackingMap.value.map)
      }
      return theMarker
    }

    // Set the selected train station segment and points
    const setupSelectedTrainStationSegmentAndPoints = async ({ trainLabel }) => {
      const tItem = trainData.trainItemsByLabel[trainLabel]
      const tData = tItem?.data

      if (tData) {
        const startCoords = trainLastStationCoordsForData(tData)
        const endCoords = trainNextStationCoordsForData(tData)
        const lastStationCode = trainLastStationCodeForData(tData)
        const nextStationCode = trainNextStationCodeForData(tData)
        const { destCodes, previousCodes } = trainSourceAndDestinationStationsListForSegment({ trainDatum: tData, lastStationCode, nextStationCode })
        const trainMovementInfo = {
              trainId: trainLabel,
              route: tData.routeName ?? '<NULL ROUTE>',
              trainNum: `${tData.trainNum}`,
              startLat: startCoords[1],
              startLng: startCoords[0],
              endLat: endCoords[1],
              endLng: endCoords[0],
              lastStationCode,
              nextStationCode,
              allDestinationCodes: destCodes,
              allPreviousCodes: previousCodes
            }

        // Fetch station segment
        const segmentParams = {
                train: trainMovementInfo.trainNum,
                route: trainMovementInfo.route,
                origin: trainMovementInfo.lastStationCode,
                destination: trainMovementInfo.nextStationCode
              }
        const segmentFromCache = await cachedGetStationSegment(segmentParams)
        if (segmentFromCache) {
          selectedTrainStationSegment.value = segmentFromCache
          // Points for selected train
          const segmentPoints = await cachedGetStationSegmentPoints({
            route: trainMovementInfo.route,
            train: trainMovementInfo.trainNum,
            destStationCodes: trainMovementInfo.allDestinationCodes,
            previousStationCodes: trainMovementInfo.allPreviousCodes,
            maxResults: pathFinderSegmentPointsMax.value,
            useProd: useProductionHistoricalDataAPI.value
          })
          selectedTrainStationSegmentPoints.value = segmentPoints?.results ?? []
        } else {
          // Render station segment
          const { sortedPoints, pathPoints } = await fetchAndRenderTrainStationSegment({
                    route: trainMovementInfo.route,
                    trainNum: trainMovementInfo.trainNum,
                    originLat: trainMovementInfo.startLat,
                    originLng: trainMovementInfo.startLng,
                    destLat: trainMovementInfo.endLat,
                    destLng: trainMovementInfo.endLng,
                    maxResults: pathFinderSegmentPointsMax.value,
                    destStationCodes: trainMovementInfo.allDestinationCodes,
                    previousStationCodes: trainMovementInfo.allPreviousCodes,
                    curveLimitDegrees: pathFinderMaxTrackCurvatureDegrees.value,
                    curveLimitRangeFeet: pathFinderMaxTrackCurvatureRangeFeet.value,
                    curveLimitSkipMax: pathFinderMaxTrackCurvatureSkip.value,
                    hintPathFailsafeThresholdRatio: pathFinderHintFailsafeThresholdRatio.value,
                    useProd: useProductionHistoricalDataAPI.value
                  })
          selectedTrainStationSegment.value = sortedPoints ?? []
          selectedTrainStationSegmentPoints.value = pathPoints ?? []
        }
      }
    }

    // Update the station segment paths for specified trains
    // If provided, use the lastStationCodes, nextStationCodes, lastStationCoords, nextStationCoords values
    // Otherwise, use the train's current lastStationCode, nextStationCode, lastStationCoord, nextStationCoord
    const refreshStationSegmentPaths = async ({
      trainLabels,
      lastStationCodes = null,
      nextStationCodes = null,
      lastStationCoords = null,
      nextStationCoords = null
    }) => {
      if (!trainLabels) {
        return
      }

      const trainLabelsNeedingStationSegments = {}
      // Setup requests for refreshed data
      trainLabels.forEach((trainLabel, idx) => {
        const tItem = trainData.trainItemsByLabel[trainLabel]
        const tData = tItem?.data

        if (tData) {
          const startCoords = lastStationCoords?.[idx] ?? trainLastStationCoordsForData(tData)
          const endCoords = nextStationCoords?.[idx] ?? trainNextStationCoordsForData(tData)
          const lastStationCode = lastStationCodes?.[idx] ?? trainLastStationCodeForData(tData)
          const nextStationCode = nextStationCodes?.[idx] ?? trainNextStationCodeForData(tData)
          const { destCodes, previousCodes } = trainSourceAndDestinationStationsListForSegment({ trainDatum: tData, lastStationCode, nextStationCode })
          trainLabelsNeedingStationSegments[trainLabel] = {
                trainId: trainLabel,
                route: tData.routeName ?? '<NULL ROUTE>',
                trainNum: `${tData.trainNum}`,
                startLat: startCoords[1],
                startLng: startCoords[0],
                endLat: endCoords[1],
                endLng: endCoords[0],
                lastStationCode,
                nextStationCode,
                allDestinationCodes: destCodes,
                allPreviousCodes: previousCodes
              }
        }

      })

      const labelsNeedingSegmentsList = Object.values(trainLabelsNeedingStationSegments)

      if (labelsNeedingSegmentsList?.length > 0) {
            // Build list of trains requiring data from server
            const labelsNeedingSegmentData = []
            for (const trainMovementInfo of labelsNeedingSegmentsList) {
              // Check if train segment is already cached
              const segmentParams = {
                train: trainMovementInfo.trainNum,
                route: trainMovementInfo.route,
                origin: trainMovementInfo.lastStationCode,
                destination: trainMovementInfo.nextStationCode
              }
              const segmentFromCache = await cachedGetStationSegment(segmentParams)
              if (segmentFromCache) {
                trainStationSegmentsById[trainMovementInfo.trainId] = segmentFromCache
                // trainData.trainItemsByLabel[trainMovementInfo.trainId].routeSegmentPath = segmentFromCache
                // trainData.trainItemsByLabel[trainMovementInfo.trainId].routeSegmentPoints = segmentFromCache
                
              } else {
                labelsNeedingSegmentData.push(trainMovementInfo)
              }
            }
            
            // const movementFetches = {
            //   stationSegmentRequests: await getTrainMovements({ trains: labelsNeedingSegmentData, useProd: useProductionHistoricalDataAPI.value }),
            // }
            
            // Fetch and Process the results
            const trainDataProcessingPromises = labelsNeedingSegmentData.map(async (trainMovementInfo) => {
              if (trainMovementInfo) {
                const segmentParams = {
                  route: trainMovementInfo.route,
                  origin: trainMovementInfo.lastStationCode,
                  destination: trainMovementInfo.nextStationCode
                }

                const { sortedPoints } = await fetchAndRenderTrainStationSegment({
                  route: trainMovementInfo.route,
                  trainNum: trainMovementInfo.trainNum,
                  originLat: trainMovementInfo.startLat,
                  originLng: trainMovementInfo.startLng,
                  destLat: trainMovementInfo.endLat,
                  destLng: trainMovementInfo.endLng,
                  maxResults: pathFinderSegmentPointsMax.value,
                  destStationCodes: trainMovementInfo.allDestinationCodes,
                  previousStationCodes: trainMovementInfo.allPreviousCodes,
                  curveLimitDegrees: pathFinderMaxTrackCurvatureDegrees.value,
                  curveLimitRangeFeet: pathFinderMaxTrackCurvatureRangeFeet.value,
                  curveLimitSkipMax: pathFinderMaxTrackCurvatureSkip.value,
                  hintPathFailsafeThresholdRatio: pathFinderHintFailsafeThresholdRatio.value,
                  useProd: useProductionHistoricalDataAPI.value
                })

                // Update cache (if necessary)
                if (sortedPoints) {
                  if (!segmentParams.origin || !segmentParams.destination || !segmentParams.route) {
                    logger.debug(`Train movement object missing route or station code(s)!: ${JSON.stringify(trainMovementInfo)}`)
                  }
                }
                
                // Station Segment Path
                trainStationSegmentsById[trainMovementInfo.trainId] = sortedPoints
              }
            })
            // Wait for all train updates to complete
            await Promise.all(trainDataProcessingPromises)
            logger.debug(`Finished refreshing train station segment data.`)
      } else {
        logger.debug(`No station segment refresh needed.`)
      }
    }

    // Update the approx path for the specified trains
    const refreshTrainApproxPaths = async ({ trainLabels }) => {
      if (!trainLabels) {
        return
      }

      const trainLabelApproxPathRequests = {}
      // Setup requests for refreshed data
      trainLabels.forEach((trainLabel) => {
        const tItem = trainData.trainItemsByLabel[trainLabel]
        const tData = tItem?.data

        if (tData) {
          trainLabelApproxPathRequests[trainLabel] = {
                trainId: trainLabel,
                route: tData.routeName ?? '<NULL ROUTE>',
                trainNum: `${tData.trainNum}`
              }
        }

      })

      const trainApproxPathRequestList = Object.values(trainLabelApproxPathRequests)

      if (trainApproxPathRequestList?.length > 0) {
            // Build list of trains requiring data from server
            const approxPathFetchPromises = []
            trainApproxPathRequestList.forEach((requestInfo) => {
              // Check if train route sample is already cached
              const routeSampleParams = {
                route: requestInfo.route,
                trainNum: requestInfo.trainNum
              }
              const routeSampleIsCached = checkForRouteSampleInCache(routeSampleParams)
              if (routeSampleIsCached) {
                // Update from cache now
                const sampleMovement = getRouteSampleFromCache(routeSampleParams)
                trainData.trainItemsByLabel[requestInfo.trainId].approxPath = sampleMovement
                
              } else {
                const fetchAndProcessRouteSample = async () => {
                  const sample = await getRouteSampleMovement({ route: requestInfo.route, trainNum: requestInfo.trainNum, useProd: useProductionHistoricalDataAPI.value })
                  if (sample) {
                    // Store path data
                    trainData.trainItemsByLabel[requestInfo.trainId].approxPath = sample

                    // Update cache
                    insertRouteSampleInCache({ route: requestInfo.route, trainNum: requestInfo.trainNum, data: sample })
                    logger.debug(`Inserted route movement sample into cache for '${requestInfo.route} #${requestInfo.trainNum}' with size: ${sample.length}`)
                  }
                }
                approxPathFetchPromises.push(
                  fetchAndProcessRouteSample()
                )
              }
            })
            
            await Promise.all(approxPathFetchPromises)
            logger.debug(`Finished refreshing train approx path data.`)
      } else {
        logger.debug(`No train approx path refresh needed.`)
      }
    }

    const initializeTrainData = async () => {
      // Load the train data
      isLoadingTrainData.value = true

      // Build a list of trains that need movement rendering from the jump
      const labelsNeedingMove = {}

      const fetchedPromises = {
        allDataWithTransitions: getTrainDataWithTransitions(),
        news: getTrainNews(),
        statistics: renderStatisticsTrivia(),
        routes: getRoutes()
      }
      const fetched = await promiseAllProperties(fetchedPromises)
      if (fetched.routes) {
        cacheViewerRoutes.value = [cacheViewerRouteAny].concat(fetched.routes)
      }
      if (fetched.allDataWithTransitions?.current) {
        const allTrains = fetched.allDataWithTransitions.current.trains // List of raw train objects from Amtrak server
        const stationsByCode = fetched.allDataWithTransitions.current.stations // Map of station data by station code
        const trainsSHA256 = fetched.allDataWithTransitions.current.trainsSHA256 ?? null // Hash of the current train data set
        const routePointsHist = null // await getHistoricalRouteData({ useProd: useProductionHistoricalDataAPI.value }) // Map of historical route geo locations by route name
        if (allTrains && stationsByCode && trainsSHA256) {
          trainData.trainsSHA256 = trainsSHA256
          const trainTransitionsTable = fetched.allDataWithTransitions.transitions ?? {}
          // Assign latest station data
          Object.assign(trainData.trainStationsByCode, stationsByCode)

          // Initialize train data
          allTrains.forEach(trainDatum => {
            // Store route name
            const trainRoute = trainDatum.routeName
            if (trainRoute) {
              trainData.trainRoutes[trainRoute] = {
                points: routePointsHist?.[trainRoute] ?? null
              }
            }

            // Create a label for each train object
            const trainLabel = trainItemLabelForData(trainDatum)
            trainData.trainItemLabels[trainLabel] = {
              route: trainRoute ?? null,
              title: trainItemShortLabelForData(trainDatum),
              removed: false // Data no longer available upstream (on server)
            }

            // Check for transition data for this train
            const trainNum = `${trainDatum.trainNum}`
            const originTimestamp = new Date(trainDatum.origSchDep).toISOString()

            const transitionData = trainTransitionsTable[`${trainRoute}_${trainNum}_${originTimestamp}`]

            if (transitionData) {
              // Check old vs new coords, stations, etc
              const newCoords = trainCoordsForData(trainDatum)
              const oldCoords = { lat: transitionData[1].lat, lng: transitionData[1].lng }
              const lastStationCode = transitionData[1].previousCode ?? null
              const nextStationCode = trainNextStationCodeForData(trainDatum)
              const lastStationCoords = coordsForStationData(trainData.trainStationsByCode[lastStationCode]) // trainLastStationCoordsForData(trainData.trainItemsByLabel[trainLabel].data)
              const nextStationCoords = trainNextStationCoordsForData(trainDatum)
              const previousTrainTS = transitionData[1].lastValTS ?? ''
              // Update the data
              if (oldCoords && newCoords && !areCoordsEqual(oldCoords, newCoords)) {
                labelsNeedingMove[trainLabel] = {
                  trainId: trainLabel,
                  route: trainRoute ?? '<NULL ROUTE>',
                  trainNum: `${trainDatum.trainNum}`,
                  startLat: oldCoords.lat,
                  startLng: oldCoords.lng,
                  endLat: newCoords.lat,
                  endLng: newCoords.lng,
                  lastStationCode,
                  lastStationLat: lastStationCoords.lat,
                  lastStationLng: lastStationCoords.lng,
                  nextStationCode,
                  nextStationLat: nextStationCoords[1],
                  nextStationLng: nextStationCoords[0]
                }
                
                trainData.trainItemsByLabel[trainLabel] = {
                  data: trainDatum, // current train data
                  marker: setupMarkerForTrain(trainLabel, trainDatum), // map marker instance
                  previousCoords: oldCoords, // retain previous coords during update (to animate transitions on map)
                  previousTrainTS: previousTrainTS, // retain previous timestamp after update to show movement times on map
                  path: null, // way points for a moving train
                  approxPath: null, // new path traversal rendered path
                  pathSegments: null // metadata for connected train path
                }
              } else {         
                trainData.trainItemsByLabel[trainLabel] = {
                  data: trainDatum, // current train data
                  marker: setupMarkerForTrain(trainLabel, trainDatum), // map marker instance
                  previousCoords: null, // retain previous coords during update (to animate transitions on map)
                  previousTrainTS: null, // retain previous timestamp after update to show movement times on map
                  path: null, // way points for a moving train
                  approxPath: null, // new path traversal rendered path
                  pathSegments: null // metadata for connected train path
                }
              }
            } else {
              // Init the train data using the current info
              trainData.trainItemsByLabel[trainLabel] = {
                data: trainDatum, // current train data
                marker: setupMarkerForTrain(trainLabel, trainDatum), // map marker instance
                previousCoords: null, // retain previous coords during update (to animate transitions on map)
                previousTrainTS: null, // retain previous timestamp after update to show movement times on map
                path: null, // way points for a moving train
                approxPath: null, // new path traversal rendered path
                pathSegments: null // metadata for connected train path
              }
            }
            
          })

          // Update Service Disruptions
          Object.assign(trainData.serviceDisruptions, allServiceDisruptedTrainLabels({ allTrains }))
        }
      }
      if (fetched.news) {
        Object.assign(trainData.trainNewsItems, fetched.news)
      }
      if (fetched.statistics) {
        Object.assign(trainData.statistics, appendServiceDisruptionInfoToTrivia({ triviaItems: fetched.statistics, serviceDisruptionTrainLabels: trainData.serviceDisruptions }))
      }
      isLoadingTrainData.value = false

      // Now show train movements
      const labelsNeedingMoveList = Object.values(labelsNeedingMove)
      if (labelsNeedingMoveList?.length > 0) {
        const transitionDurationMS = await processAndAnimateTrainMovements({ labelsNeedingMove })
        setTimeout(refreshTrainDataWorker, Math.max(dataRefreshPeriodMS - transitionDurationMS, 1))
      } else {
        // No Movements; Schedule a refresh
        setTimeout(refreshTrainDataWorker, dataRefreshErrorOrEmptyPeriodMS)
      }
    }

    // const fitMapToCoords = (coordsList) => {
    //   // Setup bounds instance
    //   var latlngbounds = new trackingMap.value.api.LatLngBounds()
    //   // Extend bound with each provided coord
    //   coordsList.forEach(({ lat, lng }) => {
    //     latlngbounds.extend(new trackingMap.value.api.LatLng(lat, lng))
    //   })
    //   // Set Bounds to the map
    //   trackingMap.value.map.fitBounds(latlngbounds)
    // }
  
    // Station Data Helpers
    const coordsForStationData = (stationDatum) => {
      const coords = {
        lat: stationDatum?.geometry?.coordinates?.[1] ?? 0,
        lng: stationDatum?.geometry?.coordinates?.[0] ?? 0
      }
      if (coords.lat === 0 && coords.lng === 0) {
        logger.debug(`No info/coords for station: ${JSON.stringify(stationDatum, null, 2)}`)
      }
      return coords
    }

    const refreshTrainStationInfo = (stationCode) => {
      logger.debug(`Fetching info for station '${stationCode}'...`)
      if (trainData.trainStationsByCode[stationCode]) {
        const stationCoords = coordsForStationData(trainData.trainStationsByCode[stationCode])
        if (!trainData.trainStationInfoByCode[stationCode]) {
          trainData.trainStationInfoByCode[stationCode] = {}
        }
        // Weather
        trainData.trainStationInfoByCode[stationCode].currentWeather = `Loading...`
        getWeatherDetailsForLocation(stationCoords).then(({ weatherDescription } = {}) => {
          trainData.trainStationInfoByCode[stationCode].currentWeather = weatherDescription
        })
        // Elevation
        if (trackingMap.value && !trainData.trainStationInfoByCode[stationCode].elevationFeet) {
          trainData.trainStationInfoByCode[stationCode].elevationFeet = `Loading...`
          elevationInFeetForCoords({ 
            mapObject: trackingMap.value,
            coords: stationCoords,
            logger
          }).then((elevationFeet) => {
            trainData.trainStationInfoByCode[stationCode].elevationFeet = `${elevationFeet} feet`
          })
        }
      }
    }

    // Train List Helpers
    const selectedRoute = ref([])
    const trainLabelsForRoute = ref([])
    const selectedTrainForSelect = ref([])
    const removedTrainItemColor = computed(() => {
      return darkThemeSwitch.value ? '#fb9797' : '#902b2b'
    })
    const selectedTrain = computed(() => {
      const selectedTitle = selectedTrainForSelect.value?.[0]
      return selectedTitle ? trainData.trainItemsByLabel[trainLabelForTitle(selectedTitle)] : null
    })

    const trainDataForTitle = (trainTitle) => {
      const trainLabel = trainLabelForTitle(trainTitle)
      return trainData.trainItemsByLabel[trainLabel]?.data ?? null
    }

    const trainLabelForTitle = (trainTitle) => {
      for (const trainLabel of Object.keys(trainData.trainItemLabels)) {
          const thisTitle = trainData.trainItemLabels[trainLabel]?.title
          if (trainTitle === thisTitle) {
            return trainLabel
          }
        }
        return null
    }

    const trainLabelObjectForTitle = (trainTitle) => {
      for (const trainLabel of Object.keys(trainData.trainItemLabels)) {
          const thisTitle = trainData.trainItemLabels[trainLabel]?.title
          if (trainTitle === thisTitle) {
            return trainData.trainItemLabels[trainLabel]
          }
        }
        return null
    }

    const sortedRoutes = computed(() => {
      return Object.keys(trainData.trainRoutes).sort()
    })

    const routeSelectEvent = (arg) => {
      // Check for a new selection
      if (selectedRoute.value?.[0] !== arg) {
        logger.debug(`Route selection changed: ${arg}`)
        selectedRoute.value = [arg]
        // Clear the train selection
        selectedTrainForSelect.value = []
        // Determine legal train selection options
        const legalTrainNumTitleTuples = []
        for (const trainLabel of Object.keys(trainData.trainItemLabels)) {
          const thisRoute = trainData.trainItemLabels[trainLabel]?.route
          if (selectedRoute.value[0] === thisRoute) {
            // Keep legal train number / title
            const thisTrainTitle = trainData.trainItemLabels[trainLabel].title
            const thisTrainNum = Number(trainData.trainItemsByLabel[trainLabel].data.trainNum)
            legalTrainNumTitleTuples.push({ num: thisTrainNum, title: thisTrainTitle })
          }
        }
        // Sort the legal train titles by train number and then by title
        // logger.debug(`Sorting '${selectedRoute.value[0]}' trains: ${JSON.stringify(legalTrainNumTitleTuples, null, 2)}`)
        legalTrainNumTitleTuples.sort((a, b) => {
            if (a.num < b.num) {
                return -1
            } else if (a.num > b.num) {
                return 1
            } else if (a.title < b.title) {
              return -1
            } else if (a.title > b.title) {
              return 1
            }
            return 
        })
        trainLabelsForRoute.value = legalTrainNumTitleTuples.map(tuple => tuple.title)
      }
    }
    const trainSelectEvent = (arg) => {
      // Check for a new selection
      if (selectedTrainForSelect.value?.[0] !== arg) {
        const selectedLabel = trainLabelForTitle(arg)
        logger.debug(`Train selection changed: ${selectedLabel}`)
        trainSelected({ label: selectedLabel, panTo: true })
        selectedTrainForSelect.value = [arg]        
      }
    }

    // Route Segment Cache Viewer
    const cacheViewerRoutes = ref([])
    const cacheViewerRoute = ref([])
    const cacheViewerTrainRoute = ref(null)
    const cacheViewerSegments = ref([])
    const cacheViewerTrains = ref([])
    const selectedCacheViewerSegment = ref([])
    const selectedCacheViewerTrain = ref([])
    const selectedCacheViewerSegmentPath = ref([])
    const cacheViewerRenderedPath = ref([])
    const showCacheViewerSegmentPoints = ref(false)
    const selectedCacheViewerSegmentPoints = ref([])

    const fetchCacheViewerSelectedSegment = ({ route, origin, dest }) => {
      getStationSegment({ route, origin, destination: dest, useProd: false }).then((theRoute) => {
          selectedCacheViewerSegmentPath.value = theRoute
        })
        getStationSegmentPoints({
          route,
          previousStationCodes: [origin],
          destStationCodes: [dest],
          maxResults: pathFinderSegmentPointsMax.value,
          useProd: useProductionHistoricalDataAPI.value
        }).then(({ results }) => {
          selectedCacheViewerSegmentPoints.value = results
        })
    }

    const cacheViewerRouteSelected = (arg) => {
      // Check for a new selection
      if (cacheViewerRoute.value?.[0] !== arg) {
        logger.debug(`Cache viewer route changed: ${arg}`)
        cacheViewerRoute.value = [arg]
        // Clear the train selection
        selectedCacheViewerSegment.value = []
        selectedCacheViewerTrain.value = []
        cacheViewerRenderedPath.value = []
        // Determine legal train selection options
        cacheViewerSegments.value = []
        cacheViewerTrains.value = []
        cacheViewerTrainRoute.value = null
        getSegmentsForRoute({ route: arg === cacheViewerRouteAny ? null : arg, useProd: false /* useProductionHistoricalDataAPI.value */ }).then((result) => {
          cacheViewerSegments.value = result?.map((aSegmentKey) => (aSegmentKey.split('_').join(' -> ')))?.sort() ?? []
        })
      }
    }
    const cacheViewerSegmentSelected = (arg) => {
      // Check for a new selection
      if (selectedCacheViewerSegment.value?.[0] !== arg && arg.includes(' -> ')) {
        // const selectedLabel = trainLabelForTitle(arg)
        logger.debug(`Cache viewer segment changed: ${arg}`)
        // trainSelected({ label: selectedLabel, panTo: true })
        selectedCacheViewerSegment.value = [arg]
        selectedCacheViewerTrain.value = []
        cacheViewerRenderedPath.value = []
        cacheViewerTrainRoute.value = null

        // Load segment data points
        const [origin, dest] = arg?.split(" -> ") ?? []
        if (origin) {
          const originStationCoords = coordsForStationData(trainData.trainStationsByCode[origin])
          panTrackingMap(originStationCoords)
          zoomTrackingMap(defaultTrackingMapTrainFocusZoom)
        }
        getTrainsForSegment({ route: cacheViewerRoute.value?.[0] === cacheViewerRouteAny ? null : cacheViewerRoute.value?.[0], origin, destination: dest, useProd: false }).then((trains) => {
          cacheViewerTrains.value = trains?.sort() ?? []
        })
        if (cacheViewerRoute.value?.[0] !== cacheViewerRouteAny) {
          fetchCacheViewerSelectedSegment({ origin, dest, route: cacheViewerRoute.value?.[0] })
        }
      }
    }

    const cacheViewerTrainSelected = (arg) => {
      // Check for a new selection
      if (selectedCacheViewerTrain.value?.[0] !== arg) {
        // const selectedLabel = trainLabelForTitle(arg)
        logger.debug(`Cache viewer train changed: ${arg}`)
        // trainSelected({ label: selectedLabel, panTo: true })
        selectedCacheViewerTrain.value = [arg]
        cacheViewerRenderedPath.value = []

        if (cacheViewerRoute.value?.[0] === cacheViewerRouteAny) {
          // Get route for the selected train
          getRouteForTrain({ train: arg }).then((route) => {
            cacheViewerTrainRoute.value = route
            const [origin, dest] = selectedCacheViewerSegment.value?.[0]?.split(" -> ") ?? []
            fetchCacheViewerSelectedSegment({ origin, dest, route })
          })
        } 
      }
    }
    
    const cacheViewerRenderSelected = () => {
      // Gather Render Inputs
      const route = cacheViewerRoute.value?.[0] === cacheViewerRouteAny ? cacheViewerTrainRoute.value ?? '' : cacheViewerRoute.value?.[0] ?? ''
      const [origin, dest] = selectedCacheViewerSegment.value?.[0]?.split(" -> ") ?? ['', '']
      const train = selectedCacheViewerTrain.value?.[0] ?? ''

      const originStationCoords = coordsForStationData(trainData.trainStationsByCode[origin])
      const destStationCoords = coordsForStationData(trainData.trainStationsByCode[dest])

      // Render station segment
      cacheViewerRenderedPath.value = []
      fetchAndRenderTrainStationSegment({
        route,
        trainNum: train,
        originLat: originStationCoords.lat,
        originLng: originStationCoords.lng,
        destLat: destStationCoords.lat,
        destLng: destStationCoords.lng,
        maxResults: pathFinderSegmentPointsMax.value,
        destStationCodes: [dest],
        previousStationCodes: [origin],
        curveLimitDegrees: pathFinderMaxTrackCurvatureDegrees.value,
        curveLimitRangeFeet: pathFinderMaxTrackCurvatureRangeFeet.value,
        curveLimitSkipMax: pathFinderMaxTrackCurvatureSkip.value,
        hintPathFailsafeThresholdRatio: pathFinderHintFailsafeThresholdRatio.value,
        useProd: useProductionHistoricalDataAPI.value
      }).then(({ sortedPoints }) => {
        cacheViewerRenderedPath.value = sortedPoints
      })
    }

    const cacheViewerPushRenderSelected = () => {
      const route = cacheViewerRoute.value?.[0] === cacheViewerRouteAny ? cacheViewerTrainRoute.value ?? '' : cacheViewerRoute.value?.[0] ?? ''
      const [origin, dest] = selectedCacheViewerSegment.value?.[0]?.split(" -> ") ?? ['', '']
      if (route && origin && dest && cacheViewerRenderedPath.value?.length) {
        logger.debug(`Inserting segment cache data (size: ${cacheViewerRenderedPath.value.length}) for ${route}, ${origin} -> ${dest}`)
        setStationSegment({ route, origin, destination: dest, data: cacheViewerRenderedPath.value, apiKey: adminKey.value, useProd: false }).then(() => {
          // Pull the latest data set from the cache
          getStationSegment({
            route,
            origin,
            destination: dest,
            useProd: false
          }).then((theRoute) => {
            selectedCacheViewerSegmentPath.value = theRoute
          })
        })
      }
    }

    const cacheViewerImportGeoJSONSelected = (inputFile) => {
      // Check for a new selection
      if (inputFile) {
        cacheViewerRenderedPath.value = []
        logger.debug(`Cache viewer importing GeoJSON from file: ${inputFile.name}`)
        const fReader = new FileReader()
        fReader.readAsText(inputFile)
        fReader.addEventListener('loadend', (e) => {
          const result = e?.target?.result
          if (result) {
            try {
              const importedGeoJSON = JSON.parse(result)
              const pathFromGeoJSON = pathFromGeoJSONLineString({ geoJSONData: importedGeoJSON })
              cacheViewerRenderedPath.value = pathFromGeoJSON
            } catch (error) {
              logger.error(`Error importing GeoJSON file data: ${error}`)
            }
          }
        })
        fReader.addEventListener('error', (e) => {
          const error = e?.target?.error?.name
          if (error) {
              logger.error(`Error importing GeoJSON file: ${error}`)
          }
        })
      }
    }

    const panToSelectedTrainMarkerIfNotVisible = () => {
      let panned = false
      const theTrain = selectedTrain.value
      if (trackingMap.value && theTrain) {
        const currentPos = theTrain.marker.getPosition()
        if (trackingMap.value.map.getBounds().contains(currentPos)) {
          // already visible
        } else {
          // pan to marker
          panTrackingMap(currentPos)
          panned = true
        }
      }
      return panned
    }

    const increaseZoomToChaseModeLevelIfNeeded = () => {
      const curZoom = getCurrentMapZoom()
      if (curZoom < defaultTrackingMapChaseModeZoom) {
        zoomTrackingMap(defaultTrackingMapChaseModeZoom)
      }
    }

    watch(followSelectedTrain, (newVal, oldVal) => {
      if (oldVal === false && newVal === true) {
        const theTrain = selectedTrainForSelect.value?.[0]
        const theRoute = selectedRoute.value?.[0]
        if (theRoute && theTrain) {
          logger.debug(`'Follow this train' selected: '${theRoute} ${theTrain}'...`)
          panToSelectedTrainMarkerIfNotVisible()
        }
      }

      if (newVal === false) {
        // Make sure 'Chase Mode' is also disabled
        chaseSelectedTrain.value = false
      }
    })

    watch(chaseSelectedTrain, (newVal) => {
      if (newVal === true) {
        // Bump Zoom to 'chase mode' level (if necessary)
        increaseZoomToChaseModeLevelIfNeeded()
        // Set maximum tilt provided for current zoom level
        setTrackingMapTilt(mapTiltSliderMax.value)
      }
    })

    // Utils

    const waitForPeriod = async (periodMS) => {
      await new Promise(resolve => setTimeout(resolve, periodMS))
    }

    const areCoordsEqual = (oldCoords, newCoords) => {
      return oldCoords?.lat === newCoords?.lat && oldCoords?.lng === newCoords?.lng
    }

    // Train movement rendering
    const useHighPerformanceMovementRendering = ref(false)

    // update the train marker icons based on train and map state (throttled)
    let updatingTrainMarkerIcons = false
    const updateTrainMarkerIcons = () => {
      if (updatingTrainMarkerIcons) return
      updatingTrainMarkerIcons = true
      // Iterate over all the trains, update marker icon
      for (const [trainLabel, trainItem] of Object.entries(trainData.trainItemsByLabel)) {
        // if (trainItem.previousCoords) {
        //   // TODO: log previous heading for correct orientation
        // }

        // Update the train's icon
        trainData.trainItemsByLabel[trainLabel].marker.setIcon(getMarkerIcon({ train: trainItem }))
      }
      setTimeout(() => {
        updatingTrainMarkerIcons = false
      }, 1000)
    }

    // update the train station marker icons based on train and map state (throttled)
    let updatingStationMarkerIcons = false
    const updateStationMarkerIcons = () => {
      if (updatingStationMarkerIcons) return
      updatingStationMarkerIcons = true
      // HACK: Force refresh of marker
      trainData.trainStationsByCode['ZZZZ'] = null
      delete trainData.trainStationsByCode['ZZZZ']
      setTimeout(() => {
        updatingStationMarkerIcons = false
      }, 1000)
    }

    const trainTransitionProgressPercent = ref(0)
    const trainTransitionProgressContent = ref('Waiting for Next Train Data Update.')

    // Animate all train marker coordinates from previous -> current (in incremental steps) to simulate fluid motion
    // Returns duration of transition (MS)
    const transitionTrainMarkers = async (labelsNeedingMove) => {
      const transitionStart = new Date().getTime()
      
      // Setup transition parameters
      const refreshIntervalMS = 33 // 30fps data refresh (base) rate
      const selectedTrainNormalClockDivider = 10 // 3fps for 'normal' rendering mode
      const selectedTrainHighPerfClockDivider = 1 // 30fps for 'high performance' rendering mode
      const otherTrainClockDivider = 10 // 3fps update rate for non-selected train markers
      const progressUpdateClockDivider = 10 // 3fps for the progress indicator
      const panToSelectedTrainNormalClockDivider = 30 // 1fps
      const panToSelectedTrainHighPerfClockDivider = 1 // 30fps
      const updateCockpitCamElevationClockDivider = 30 // 1 fps
      const updateCockpitCamClockDivider = 1 // 30fps
      const iconUpdateClockDivider = 30 // 1Hz for train icon updates
      const chaseModeUpdateClockDivider = 60 // 0.5Hz for selected train chase mode updates
      const moveDurationMS = dataRefreshPeriodMS - 5000 // just shy of the data refresh period (allow for data fetch and other logic)
      const movementIncrements = Math.floor(moveDurationMS / refreshIntervalMS)

      // Update Progress Indicator
      trainTransitionProgressPercent.value = 0

      // Main 'tick' loop
      if (movementIncrements > 0) {
        for (let tickIndex = 0; tickIndex < movementIncrements; tickIndex++) {
          const tickStart = new Date().getTime()
          // Update flags
          const isLastTick = tickIndex + 1 === movementIncrements
          const updateSelectedTrain = tickIndex % (useHighPerformanceMovementRendering.value ? selectedTrainHighPerfClockDivider : selectedTrainNormalClockDivider) === 0
          const updateNotSelectedTrains = tickIndex % otherTrainClockDivider === 0
          const updateProgressIndicator = tickIndex % progressUpdateClockDivider === 0
          const panToSelectedTrain = tickIndex % (useHighPerformanceMovementRendering.value ? panToSelectedTrainHighPerfClockDivider : panToSelectedTrainNormalClockDivider) === 0
          const updateTrainMarkers = tickIndex % iconUpdateClockDivider === 0
          const updateChaseMode = tickIndex % chaseModeUpdateClockDivider === 0
          const updateCockpitCamElevation = tickIndex % updateCockpitCamElevationClockDivider === 0
          const updateCockpitCam = tickIndex % updateCockpitCamClockDivider === 0

          // Check if tracking map is hidden
          const isMapHidden = isTrackingMapHiddenByFullscreenElement()

          // Iterate over all the train labels requiring a transition
          for (const trainLabel of labelsNeedingMove) {
              //logger.debug(`Checking transition for train label: '${trainLabel}'`)
              const trainTitle = trainData.trainItemLabels[trainLabel]?.title
              const trainPathSegments = trainData.trainItemsByLabel[trainLabel].pathSegments
              if (trainPathSegments) {
                if (trainTitle === selectedTrainForSelect.value?.[0]) {
                  if (updateCockpitCam || updateCockpitCamElevation || updateSelectedTrain || updateNotSelectedTrains || isLastTick) {
                    // Compute new train location
                    const { currentPosition, currentHeading } = positionAndHeadingAlongPathWith({
                      pathSegments: trainPathSegments,
                      progress: tickIndex / movementIncrements
                    })

                    if ((updateSelectedTrain && followSelectedTrain.value && !isMapHidden) || (updateNotSelectedTrains && !isMapHidden)) {
                      // Update the train's geo data + selectedTrain ref
                      trainData.trainItemsByLabel[trainLabel].marker.setPosition({ lat: currentPosition.lat, lng: currentPosition.lng })
                      if (updateProgressIndicator) {
                        // Update UI stats
                        trainData.trainItemsByLabel[trainLabel].data.heading = getHeadingStringForHeading(currentHeading)
                        trainData.trainItemsByLabel[trainLabel].data.headingDegrees = currentHeading
                      }
                    }
                    
                    if ((panToSelectedTrain && followSelectedTrain.value && !isMapHidden) || (isLastTick && followSelectedTrain.value)) {
                      // Pan the map if this is the selected train
                      panTrackingMap({ lat: currentPosition.lat, lng: currentPosition.lng })
                    }

                    if (((updateChaseMode && !isMapHidden) || isLastTick) && chaseSelectedTrain.value) {
                      // Adjust the 'Chase Mode' configuration based on this (selected) train
                      // Follow selected train from behind
                      orientTrackingMapToHeading(currentHeading)
                      // Maximum tilt provided for current zoom level
                      setTrackingMapTilt(mapTiltSliderMax.value)
                      // Correct Zoom if currently zoomed out beyond 'train focus' level
                      increaseZoomToChaseModeLevelIfNeeded()
                    }

                    if (updateCockpitCamElevation || isLastTick) {
                      // Update elevation, heading, position
                      moveCesiumCamera({ cesiumLib: cesiumLib.value, cesiumViewer: cesiumViewer.value, location: currentPosition, pitch: 0, heading: trainCameraHeadingForTrainHeading(currentHeading) })
                      clampedHeightAtPoint({ cesiumLib: cesiumLib.value, cesiumViewer: cesiumViewer.value, lat: currentPosition.lat, lng: currentPosition.lng }).then((terrainHeight) => {
                        updateCesiumCameraOrientation({ cesiumLib: cesiumLib.value, cesiumViewer: cesiumViewer.value, pitch: 0, height: terrainHeight + defaultCameraTerrainHeightOffset.value })
                      })
                    } else if (updateCockpitCam) {
                      // Update position
                      // Update cockpit view
                      //const terrainHeight = await clampedHeightAtPoint({ cesiumLib: cesiumLib.value, cesiumViewer: cesiumViewer.value, lat: currentPosition.lat, lng: currentPosition.lng })
                      moveCesiumCamera({ cesiumLib: cesiumLib.value, cesiumViewer: cesiumViewer.value, location: currentPosition, pitch: 0, heading: trainCameraHeadingForTrainHeading(currentHeading) })
                      //updateCesiumCameraOrientation({ cesiumLib: cesiumLib.value, cesiumViewer: cesiumViewer.value, heading: currentHeading, pitch: 0, /* height: terrainHeight + defaultCameraTerrainHeightOffset.value */ })
                    }
                  }
                } else if ((updateNotSelectedTrains && !isMapHidden) || isLastTick) {
                  // Update other trains less frequently
                  const { currentPosition, currentHeading } = positionAndHeadingAlongPathWith({
                    pathSegments: trainPathSegments,
                    progress: tickIndex / movementIncrements
                  })
                  // Update the train's geo data + selectedTrain ref
                  trainData.trainItemsByLabel[trainLabel].marker.setPosition({ lat: currentPosition.lat, lng: currentPosition.lng })
                  trainData.trainItemsByLabel[trainLabel].data.heading = getHeadingStringForHeading(currentHeading)
                  trainData.trainItemsByLabel[trainLabel].data.headingDegrees = currentHeading
                }
              }

              // Finally, complete the transition for this train item
              if (isLastTick) {
                trainData.trainItemsByLabel[trainLabel].previousCoords = null
                trainData.trainItemsByLabel[trainLabel].previousTrainTS = null
                logger.debug(`Finished transition of coords for train '${trainLabel}'.`)
              }
            }
          
          // Update train markers periodically
          if (updateTrainMarkers || isLastTick) {
            updateTrainMarkerIcons()
          }
          const tickEnd = new Date().getTime()
          const tickUsed = tickEnd - tickStart

          if (updateProgressIndicator) {
            // Update progress indicator
            const progressPercent = ((tickIndex/movementIncrements) * 100.0).toFixed(2)
            trainTransitionProgressPercent.value = progressPercent
            trainTransitionProgressContent.value = `${labelsNeedingMove.length} Trains Moving (${progressPercent}%)`
          }

          // Check for long page invisible spans during render
          if (lastPageBecameInvisibleDatestamp.value >= transitionStart && (lastPageBecameInvisibleDatestamp.value < lastPageBecameVisibleDatestamp.value)) {
            const invisibleSpanMS = lastPageBecameVisibleDatestamp.value - lastPageBecameInvisibleDatestamp.value
            logger.debug(`Page idle detected (${invisibleSpanMS} ms) during train movement rendering.`)
            if ((invisibleSpanMS > (60 * 1000)) && (tickIndex < (movementIncrements - 2))) {
              // "Fast forward" to the last tick (abort render)
              tickIndex = movementIncrements - 2
              logger.debug(`Long page idle detected (${invisibleSpanMS} ms). Skipping current train movement render.`)
            }
          }

          // Wait for next tick
          await waitForPeriod(refreshIntervalMS - tickUsed)
        }
      }
      const transitionEnd = new Date().getTime()
      // Update Progress Indicator
      trainTransitionProgressPercent.value = 100.0
      trainTransitionProgressContent.value = `${labelsNeedingMove.length} Trains Finished Moving. Waiting for Next Update.`
      return transitionEnd - transitionStart
    }

    const allServiceDisruptedTrainLabels = ({ allTrains }) => {
      const serviceDisruptions = []
      allTrains.forEach(trainDatum => {
        if (trainDatum.serviceDisruption === true) {
          // Create a label for each train object for UI
          const trainLabel = trainItemLabelForData(trainDatum)
          serviceDisruptions.push(trainLabel)
        }
      })
      return serviceDisruptions
    }

    const prepareTrainMovementRender = async ({ allTrains, routePointsHist }) => {
        const receivedLabels = {}
        const labelsNeedingMove = {}
        const labelsNeedingApproxPath = []
        allTrains.forEach(trainDatum => {
          // Store the route
          const trainRoute = trainDatum.routeName
          if (trainRoute) {
            trainData.trainRoutes[trainRoute] = {
              points: routePointsHist?.[trainRoute] ?? null
            }
          }
          // Create a label for each train object for UI
          const trainLabel = trainItemLabelForData(trainDatum)
          receivedLabels[trainLabel] = true
          // Update existing train objects (label already exists)
          if (trainData.trainItemLabels[trainLabel]) {
            // Capture the next, previous coords data
            const oldCoords = trainCoordsForData(trainData.trainItemsByLabel[trainLabel].data)
            const newCoords = trainCoordsForData(trainDatum)
            const lastStationCoords = trainLastStationCoordsForData(trainData.trainItemsByLabel[trainLabel].data)
            const nextStationCoords = trainNextStationCoordsForData(trainDatum)
            const lastStationCode = trainLastStationCodeForData(trainData.trainItemsByLabel[trainLabel].data)
            const nextStationCode = trainNextStationCodeForData(trainDatum)
            const previousTrainTS = trainTSForData(trainData.trainItemsByLabel[trainLabel].data)
            // Update the data
            if (oldCoords && newCoords && !areCoordsEqual(oldCoords, newCoords)) {
              labelsNeedingMove[trainLabel] = {
                trainId: trainLabel,
                route: trainRoute ?? '<NULL ROUTE>',
                trainNum: `${trainDatum.trainNum}`,
                startLat: oldCoords.lat,
                startLng: oldCoords.lng,
                endLat: newCoords.lat,
                endLng: newCoords.lng,
                lastStationCode,
                lastStationLat: lastStationCoords[1],
                lastStationLng: lastStationCoords[0],
                nextStationCode,
                nextStationLat: nextStationCoords[1],
                nextStationLng: nextStationCoords[0]
              }
              trainData.trainItemsByLabel[trainLabel].previousCoords = oldCoords
              trainData.trainItemsByLabel[trainLabel].previousTrainTS = previousTrainTS
              trainData.trainItemsByLabel[trainLabel].data = trainDatum
            } else {
              trainData.trainItemsByLabel[trainLabel].previousCoords = null
              trainData.trainItemsByLabel[trainLabel].previousTrainTS = null
              trainData.trainItemsByLabel[trainLabel].data = trainDatum
              trainData.trainItemsByLabel[trainLabel].path = null
              // trainData.trainItemsByLabel[trainLabel].approxPath = null
              // trainData.trainItemsByLabel[trainLabel].routeSegmentPath = null
              trainData.trainItemsByLabel[trainLabel].pathSegments = null
            }
            // Updated selected train elevation
            if (selectedTrainForSelect.value?.[0] === trainData.trainItemLabels[trainLabel].title) {
              updateSelectedTrainMetadata(trainCoordsForData(trainData.trainItemsByLabel[trainLabel].data))
            }
            // Check if Approx Path is required
            if (!trainData.trainItemsByLabel[trainLabel].approxPath) {
              labelsNeedingApproxPath.push(trainLabel)
            }

          } else {
            // Add the new train item, label
            trainData.trainItemsByLabel[trainLabel] = {
              data: trainDatum, // current train data
              marker: setupMarkerForTrain(trainLabel, trainDatum), // map marker instance
              previousCoords: null, // retain previous coords during update (to animate transitions on map)
              previousTrainTS: null, // retain previous train timestamp to show time span for movements
            }
            trainData.trainItemLabels[trainLabel] = {
              route: trainRoute ?? null,
              title: trainItemShortLabelForData(trainDatum),
              removed: false
            }
            labelsNeedingApproxPath.push(trainLabel)
          }
        })

        // Mark any 'orphaned' (completed) trains (label items) as 'removed'
        Object.keys(trainData.trainItemLabels).forEach(async (localLabel) => {
          if (!receivedLabels[localLabel] && trainData.trainItemLabels[localLabel].removed != true) {
            trainData.trainItemLabels[localLabel].removed = true
            // Fetch historical data from the server to capture final status
            const trainObjectIdNum = trainData.trainItemsByLabel[localLabel].data.objectID
            const originalDepartureDate = trainData.trainItemsByLabel[localLabel].data.origSchDep
            if (trainObjectIdNum && originalDepartureDate) {
              const finalTrainData = await getTrainHistory({
                trainObjectId: `${trainObjectIdNum}`,
                originTimestamp: originalDepartureDate
              })
              const finalTrainLabel = trainItemLabelForData(finalTrainData)
              if (finalTrainLabel === localLabel) {
                trainData.trainItemsByLabel[localLabel].data = finalTrainData
                const lastCoords = trainCoordsForData(trainData.trainItemsByLabel[localLabel].data)
                trainData.trainItemsByLabel[localLabel].previousCoords = null
                trainData.trainItemsByLabel[localLabel].previousTrainTS = null
                trainData.trainItemsByLabel[localLabel].path = null
                // trainData.trainItemsByLabel[localLabel].approxPath = null
                // trainData.trainItemsByLabel[localLabel].routeSegmentPath = null
                trainData.trainItemsByLabel[localLabel].pathSegments = null
                trainData.trainItemsByLabel[localLabel].marker.setPosition({ lat: lastCoords.lat, lng: lastCoords.lng })
                logger.debug(`Train has been removed upstream: '${localLabel}'. The final status was found.`)
              } else {
                logger.debug(`Train has been removed upstream: '${localLabel}'. The historical data had a different label: ${finalTrainLabel}.`)
              }
            } else {
              logger.debug(`Train has been removed upstream: '${localLabel}'.`)
            }
          }
        })

        return { labelsNeedingMove, labelsNeedingApproxPath }
    }

    const processAndAnimateTrainMovements = async ({ labelsNeedingMove }) => {
        const labelsNeedingMoveList = Object.values(labelsNeedingMove)
        trainTransitionProgressContent.value = `Fetching Movement Data for ${labelsNeedingMoveList.length} Trains...`
        // Clear any local segment data
        Object.keys(trainStationSegmentsById).forEach(key => delete trainStationSegmentsById[key])
        // Build list of trains needing station segment data from server
        const labelsNeedingStationSegment = []
        const lastStationCodesForStationSegmentFetches = []
        const nextStationCodesForStationSegmentFetches = []
        const lastStationCoordsForStationSegmentFetches = []
        const nextStationCoordsForStationSegmentFetches = []
        // Build list of trains that need train movement data from server
        const labelsUsingCachedSegmentData = []
        for (const trainMovementInfo of labelsNeedingMoveList) {
          // Check if train segment is already cached
          const segmentParams = {
            route: trainMovementInfo.route,
            train: trainMovementInfo.trainNum,
            origin: trainMovementInfo.lastStationCode,
            destination: trainMovementInfo.nextStationCode
          }
          const thisSegment = await cachedGetStationSegment(segmentParams)
          if (thisSegment) {
            // Strip station coords from obj
            labelsUsingCachedSegmentData.push({ ...trainMovementInfo, lastStationLat: null, lastStationLng: null, nextStationLat: null, nextStationLng: null })
          } else {
            labelsNeedingStationSegment.push(trainMovementInfo.trainId)
            lastStationCodesForStationSegmentFetches.push(trainMovementInfo.lastStationCode)
            nextStationCodesForStationSegmentFetches.push(trainMovementInfo.nextStationCode)
            lastStationCoordsForStationSegmentFetches.push([trainMovementInfo.lastStationLng, trainMovementInfo.lastStationLat])
            nextStationCoordsForStationSegmentFetches.push([trainMovementInfo.nextStationLng, trainMovementInfo.nextStationLat])
            labelsUsingCachedSegmentData.push({ ...trainMovementInfo, lastStationLat: null, lastStationLng: null, nextStationLat: null, nextStationLng: null })
          }
        }

        // Refresh all segment paths
        await refreshStationSegmentPaths({
          trainLabels: labelsNeedingStationSegment,
          lastStationCodes: lastStationCodesForStationSegmentFetches,
          nextStationCodes: nextStationCodesForStationSegmentFetches,
          lastStationCoords: lastStationCoordsForStationSegmentFetches,
          nextStationCoords: nextStationCoordsForStationSegmentFetches
        })

        // const movementFetches = {
        //   requestsWithoutSegments: await getTrainMovements({ trains: labelsUsingCachedSegmentData, useProd: useProductionHistoricalDataAPI.value })
        // }
        
        // Update train path info for trains
        trainTransitionProgressContent.value = `Processing Movement Data for ${labelsUsingCachedSegmentData.length} Trains...`
        const cachedTrainDataProcessingPromises = labelsUsingCachedSegmentData.map(async (trainMovementInfo) => {
          if (trainMovementInfo) {
            // Render train path using the station segment path

            // Check/get the station segment data
            if (!trainStationSegmentsById[trainMovementInfo.trainId]) {
              console.log(`Fetching Station segment data for train '${trainMovementInfo.trainId}', '${trainMovementInfo.lastStationCode}' -> '${trainMovementInfo.nextStationCode}'`)
                // Refresh the segment now
              await refreshStationSegmentPaths({
                trainLabels: [trainMovementInfo.trainId],
                lastStationCodes: [trainMovementInfo.lastStationCode],
                nextStationCodes: [trainMovementInfo.nextStationCode],
                lastStationCoords: [[trainMovementInfo.lastStationLng, trainMovementInfo.lastStationLat]],
                nextStationCoords: [[trainMovementInfo.nextStationLng, trainMovementInfo.nextStationLat]]
              })
            }
            const stationSegmentPath = structuredClone(trainStationSegmentsById[trainMovementInfo.trainId])

            if (!stationSegmentPath) {
              console.log(`Fetch failed for station segment '${trainMovementInfo.trainId}' ('${trainMovementInfo.lastStationCode}' -> '${trainMovementInfo.nextStationCode}')!`)
            }

            const trainMovement = await pathFromStationSegmentPath({
              origin: { lat: trainMovementInfo.startLat, lng: trainMovementInfo.startLng },
              destination: { lat: trainMovementInfo.endLat, lng: trainMovementInfo.endLng },
              stationSegmentPath
            })
            
            // Train movement path
            const { sortedPoints, pathSegments } = trainMovement

            trainData.trainItemsByLabel[trainMovementInfo.trainId].path = sortedPoints
            trainData.trainItemsByLabel[trainMovementInfo.trainId].pathSegments = pathSegments
            // logger.debug(`Will transition train '${trainPath.trainId}' along path: ${JSON.stringify(thePath)}`)
          }
        })
        
        // Wait for all train updates to complete
        await Promise.all(cachedTrainDataProcessingPromises)

        // Update the selected train info
        if (selectedTrainForSelect.value?.[0]) {
          const selectedLabel = trainLabelForTitle(selectedTrainForSelect.value[0])
          setupSelectedTrainStationSegmentAndPoints({ trainLabel: selectedLabel })
        }

        // Animate train markers w/ new coords
        const transitionDurationMS = await transitionTrainMarkers(Object.keys(labelsNeedingMove))
        logger.debug(`Finished transitioning train markers. It took ${transitionDurationMS} ms.`)
        return transitionDurationMS
    }

    const refreshTrainDataWorker = async () => {

        // Load the current train data
        const fetchPromises = {
          allData: getTrainDataWithTransitions({ most_recent_sha256: trainData.trainsSHA256 }),
          news: getTrainNews(),
          statistics: renderStatisticsTrivia(),
          routes: getRoutes()
        }
        const fetched = await promiseAllProperties(fetchPromises)
        if (fetched.news) {
          Object.assign(trainData.trainNewsItems, fetched.news)
        }
        if (fetched.routes) {
          cacheViewerRoutes.value = [cacheViewerRouteAny].concat(fetched.routes)
        }
        if (fetched.allData && fetched.allData.current && fetched.allData.current.trains && fetched.allData.current.stations) {
          const allTrains = fetched.allData.current.trains // List of raw train objects from Amtrak server
          const allStationsByCode = fetched.allData.current.stations
          const routePointsHist = fetched.allData.current.routes
          const trainsSHA256 = fetched.allData.current.trainsSHA256
          trainData.trainsSHA256 = trainsSHA256
          Object.assign(trainData.trainStationsByCode, allStationsByCode)

          // Update Service Disruptions
          Object.assign(trainData.serviceDisruptions, allServiceDisruptedTrainLabels({ allTrains }))

          // Process train data based on latest data
          const { labelsNeedingMove } = await prepareTrainMovementRender({ allTrains, routePointsHist })
          // Update the train icons
          updateTrainMarkerIcons()
          // Get transition data
          const labelsNeedingMoveList = Object.values(labelsNeedingMove)
          
          if (labelsNeedingMoveList?.length > 0) {
            const transitionDurationMS = await processAndAnimateTrainMovements({ labelsNeedingMove })
            setTimeout(refreshTrainDataWorker, Math.max(dataRefreshPeriodMS - transitionDurationMS, 1))
          } else {
            // No Movements; Schedule a refresh
            setTimeout(refreshTrainDataWorker, dataRefreshErrorOrEmptyPeriodMS)
          }
        } else {
          // No data; Schedule next refresh accordingly
          setTimeout(refreshTrainDataWorker, dataRefreshErrorOrEmptyPeriodMS)
        }
        if (fetched.statistics) {
          Object.assign(trainData.statistics, appendServiceDisruptionInfoToTrivia({ triviaItems: fetched.statistics, serviceDisruptionTrainLabels: trainData.serviceDisruptions }))
        }
    }

    // Page Visibility
    const visibility = useDocumentVisibility()
    const pageIsVisible = ref(true)
    const lastPageBecameInvisibleDatestamp = ref(0)
    const lastPageBecameVisibleDatestamp = ref(0)
    const kPageVisible = 'visible'
    const kPageInvisible = 'hidden'

    watch(visibility, (current, previous) => {
      if (current === kPageVisible) {
        pageIsVisible.value = true
      } else if (current === kPageInvisible) {
        pageIsVisible.value = false
      }
      if (current === kPageVisible && previous === kPageInvisible) {
        lastPageBecameVisibleDatestamp.value = new Date().getTime()
        logger.debug(`Page is now visible.`)
      } else if (current === kPageInvisible && previous === kPageVisible) {
        lastPageBecameInvisibleDatestamp.value = new Date().getTime()
        logger.debug(`Page is now hidden.`)
      }
    }, { immediate: true })

    // Window Size Mgmt
    const { mdAndUp } = useDisplay()
    const windowHeight = ref(window.innerHeight)
    const windowWidth = ref(window.innerWidth)

    watch(windowHeight, (newHeight, oldHeight) => {
      logger.debug(`Window height: ${oldHeight} --> ${newHeight}`)
    })
    watch(windowWidth, (newWidth, oldWidth) => {
      logger.debug(`Window width: ${oldWidth} --> ${newWidth}`)
    })

    const windowResizeHandler = () => {
      windowHeight.value = window.innerHeight
      windowWidth.value = window.innerWidth
    }

    const fullscreenModeChanged = () => {
      // document.fullscreenElement will point to the element that
      // is in fullscreen mode if there is one. If there isn't one,
      // the value of the property is null.
      if (document.fullscreenElement) {
        // Check if tracking map is covered (e.g. by train camera)
        logger.debug(`App entered full screen mode.`)
      } else {
        // NOTE: Workaround a cesiumjs 'bug' (?) where the resolution of the viewer (cockpit camera view) is not reset after leaving fullscreen mode by toggling the value
        const currTrainCameraViewerProps = getCesiumViewerProps({ cesiumLib: cesiumLib.value, cesiumViewer: cesiumViewer.value })
        if (currTrainCameraViewerProps?.resolutionScale != undefined) {
          setTimeout(() => {
            logger.debug(`Resetting train cam resolution after fullscreen exit...`)
            setCesiumViewerProps({ cesiumLib: cesiumLib.value, cesiumViewer: cesiumViewer.value, resolutionScale: 0.2 }) // toggle
            setTimeout(() => {
              logger.debug(`Train cam resolution restored to ${currTrainCameraViewerProps.resolutionScale}`)
              setCesiumViewerProps({ cesiumLib: cesiumLib.value, cesiumViewer: cesiumViewer.value, resolutionScale: currTrainCameraViewerProps.resolutionScale }) // restore
            }, 1000)
          }, 1000)
        }
        logger.debug("App exited full screen mode.")
      }
    }

    onMounted(async () => {
      window.addEventListener('resize', windowResizeHandler)
      document.addEventListener("fullscreenchange", fullscreenModeChanged)
      
      await initializeTrainData() // This call will also kick off periodic data refreshes

      // Initialize 'high performance rendering mode' based on client specs (best guess)
      const gpuTier = await getGPUTier();
      // Example output:
      // {
      //   "tier": 1,
      //   "isMobile": false,
      //   "type": "BENCHMARK",
      //   "fps": 21,
      //   "gpu": "intel iris graphics 6100"
      // }

      if (gpuTier?.isMobile === false && gpuTier?.tier >= 2) {
        // Enable High Performance Mode by default
        useHighPerformanceMovementRendering.value = true
      }

      logger.debug(`Client gpu tier info: ${JSON.stringify(gpuTier)}`)
    })

    onBeforeUnmount(() => {
      // stop refreshing data
      shouldRefresh.value = false
      window.removeEventListener('resize', windowResizeHandler)
    })
    
    // Theme Mgmt
    const currentTheme = ref('dark')
    const darkThemeSwitch = ref(true)

    watch(darkThemeSwitch, (val) => {
      if (val) {
        currentTheme.value = 'dark'
      } else {
        currentTheme.value = 'light'
      }
    })

    // Tracking Map Style Helpers
    // Based on compact scaling CSS from: https://stackoverflow.com/questions/49663545/is-it-possible-to-resize-vuetify-components
    const appBarUIstyle = () => {
      const styleAttribs = (mdAndUp.value ? {} : {
        transform: 'scale(0.750)',
        'transform-origin': 'top left'
      })
      // console.log(`App Bar style: ${JSON.stringify(styleAttribs)}`)
      return styleAttribs
    }

    const trainInfoUIstyle = () => {
      const styleAttribs = (mdAndUp.value ? {} : {
        transform: 'scale(0.750)',
        'transform-origin': 'bottom left'
      })
      // console.log(`App Bar style: ${JSON.stringify(styleAttribs)}`)
      return styleAttribs
    }

    // Page Info Button
    const infoDialog = ref(false)
    const infoClicked = () => {
      logger.debug('Info button clicked...')
      infoDialog.value = true
    }

    // Train Route Segment Cache Actions
    const exportSegmentCacheClicked = async () => {
        logger.debug(`Exporting segment cache...`)
        
        // Generate JSON for segment cache
        const route = cacheViewerRoute.value?.[0] === cacheViewerRouteAny ? cacheViewerTrainRoute.value ?? '' : cacheViewerRoute.value?.[0] ?? ''
        const [origin, dest] = selectedCacheViewerSegment.value?.[0]?.split(" -> ") ?? ["",""]
        
        if (!route || !origin || !dest || !selectedCacheViewerSegmentPath.value) {
          return // Missing data
        }
        
        const data = geoJSONDataFromPath({
          route,
          originCode: origin,
          destinationCode: dest,
          path: selectedCacheViewerSegmentPath.value
        })
        const bytes = new TextEncoder().encode(data)
        const blob = new Blob([bytes])
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.href = url
        const fileName = `Trak_Watch_Station_Segment_${route}_${origin}_${dest}.json`
        link.setAttribute("download", fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    // External Links

    const openURLInNewWindow = (url) => {
      window.open(url)
    }

    return {
      // App Constants
      componentTitle,
      projectVersion,
      currentTheme,
      darkThemeSwitch,
      mdAndUp, // size class bool
      windowWidth,
      windowHeight,
      // Cesium
      defaultTrackingMapLocation,
      bingMapsKey,
      cesiumViewer,
      cesiumViewerReady,
      cesiumResolutionScale,
      cesiumCameraDirectionEnum,
      ambientAudioEnabled,
      ambientAudioVolume,
      ambientAudioPlayerRef,
      onEntityEvt,
      document, // document ref for root container
      cockpitCameraMoveableDiv,
      cockpitCameraMoveableContainer,
      onCockpitCameraDrag,
      onCockpitCameraScale,
      onCockpitCameraResize,
      cockpitCameraResolutionScaleChanged,
      cockpitCameraDirectionChanged,
      ambientAudioVolumeChanged,
      isTrackingMapHiddenByFullscreenElement,
      trainCameraTitleForEnum,
      showCockpitCameraMenu,
      showCockpitAudioMenu,
      // Train Data
      trainData,
      isProd,
      useProductionHistoricalDataAPI,
      isLoadingTrainData,
      trainTransitionProgressPercent,
      trainTransitionProgressContent,
      selectedTrainStationSegment,
      selectedTrainStationSegmentPoints,
      trainStationSegmentsById,
      // Settings
      showSelectedTrainDebugInfo,
      showSelectedTrainMovementDebugInfo,
      showSelectedTrainStationSegmentTrackInfo,
      showSelectedTrainStationSegmentTrackPoints,
      showSelectedTrainRoutePoints,
      useHighPerformanceMovementRendering,
      showSelectedTrainCockpitView,
      pathFinderMaxTrackCurvatureDegrees,
      pathFinderMaxTrackCurvatureRangeFeet,
      pathFinderSegmentPointsMax,
      pathFinderMaxTrackCurvatureSkip,
      pathFinderHintFailsafeThresholdRatio,
      adminKey,
      showAdminKey,
      // Train Helpers,
      trainSelected,
      trainItemLabelForData,
      trainCoordsForData,
      rawDataStringForTrainData,
      selectedTrain,
      trainLabelsForRoute,
      // Station Helpers
      refreshTrainStationInfo,
      // Train List Helpers
      sortedRoutes,
      selectedTrainForSelect,
      selectedRoute,
      routeSelectEvent,
      trainSelectEvent,
      trainDataForTitle,
      trainLabelObjectForTitle,
      removedTrainItemColor,
      // Map Data
      googleAPIKey,
      trainMapId,
      trackingMap,
      trackingMapZoom,
      trackingMapTilt,
      trackingMapHeading,
      trackingMapOptions,
      mapDarkTheme,
      selectedTrainElevation,
      selectedTrainWeather,
      selectedTrainLocation,
      followSelectedTrain,
      chaseSelectedTrain,
      expansionPanelState,
      genralInfoExpansionPanelState,
      statsTickerExpansionPanelState,
      cacheViewerExpansionPanelState,
      selectedMapType,
      mapZoomSliderValue,
      mapZoomSliderMin,
      mapZoomSliderMax,
      mapHeadingSliderValue,
      mapHeadingSliderMin,
      mapHeadingSliderMax,
      mapTiltSliderValue,
      mapTiltSliderMin,
      mapTiltSliderMax,
      // Segment Cache Viewer
      cacheViewerRoutes,
      cacheViewerRoute,
      cacheViewerSegments,
      cacheViewerTrains,
      selectedCacheViewerSegment,
      selectedCacheViewerTrain,
      cacheViewerRouteSelected,
      cacheViewerSegmentSelected,
      cacheViewerTrainSelected,
      selectedCacheViewerSegmentPath,
      showCacheViewerSegmentPoints,
      selectedCacheViewerSegmentPoints,
      cacheViewerRenderedPath,
      cacheViewerRenderSelected,
      cacheViewerPushRenderSelected,
      cacheViewerImportGeoJSONSelected,
      // Map Helpers
      trackingMapZoomChanged,
      trackingMapTiltChanged,
      trackingMapHeadingChanged,
      trackingMapTypeChanged,
      trackingMapIdleEvent,
      mapDragStarted,
      mapZoomSliderUpdated,
      mapHeadingSliderUpdated,
      mapTiltSliderUpdated,
      mapTypeSelectEvent,
      getMarkerOptions,
      polylineOptionsWith,
      appWindow,
      activateMapFullscreen,
      // Date helpers
      humanTimeSinceDate,
      prettyDateWithFormat,
      // Info Button
      infoDialog,
      infoClicked,
      // Segment Cache Actions
      exportSegmentCacheClicked,
      // External Links
      openURLInNewWindow,
      // UI Helpers
      isStringEmpty,
      // Train Map Class Helpers
      appBarUIstyle,
      trainInfoUIstyle
    }
  }
}
</script>

<style lang="scss" scoped>

// Customize Moveable / Resizable Controls
:deep(.moveable-control) {
    // position: absolute;
    // width: 14px;
    // height: 14px;
    // border-radius: 50%;
    border: none !important;
    // box-sizing: border-box;
    background: transparent !important;
    // margin-top: -7px;
    // margin-left: -7px;
    // z-index: 10;
}

:deep(.cockpit-cam-fps) {
  position: absolute;
  top: unset;
  bottom: 32px !important;
  left: 0 !important;
  right: unset;
}

:deep(.cesium-viewer-fullscreenContainer) {
  bottom: unset;
  top: 0 !important;
}

// HACK: override style for the info window containers
:deep(.gm-style-iw-c) {
  background-color: var(--info-bg-color) !important;
	border-radius: 4px;
  overflow: hidden !important;
	padding: 0px;
}

:deep(.gm-style-iw-d) {
  overflow: hidden !important;
}

// HACK: override style for the place-of-interest info windows
:deep(.poi-info-window) {
  background-color: var(--info-bg-color) !important;
  color: var(--info-text-color) !important;
}
:deep(.poi-info-window div) {
  background-color: var(--info-bg-color) !important;
  color: var(--info-text-color) !important;
}
:deep(.poi-info-window a) {
  background-color: var(--info-bg-color) !important;
}
:deep(.gm-style-iw .gm-title) {
  background-color: var(--info-bg-color) !important;
  color: var(--info-text-color) !important;
}
// HACK: override style for 'x' (close) button color in the info windows
:deep(.gm-ui-hover-effect span) {
  background-color: var(--info-close-color) !important;
}

// HACK: override style for the info window "stem" pointer polygon
:deep(.gm-style-iw-tc::after) {
	background-color: var(--info-bg-color);
}

// HACK: fix v-combobox text entry field width bug
:deep(.v-field__input > input) {
  width: 100%;
}

// HACK: limit v-combobox item list height
:deep(.v-menu .v-overlay__content > .v-list) {
	max-height: 450px !important;
}

// trainNewsExpansionPanelContent
:deep(#trainNewsExpansionPanelContent > .v-expansion-panel-text__wrapper) {
  padding: 0 !important;
}

// Clickable Links

a:link {
  color: #005179; // Amtrak Blue Color
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: #005179;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: #005179;
  background-color: transparent;
  text-decoration: none;
}

a:active {
  color: #005179;
  background-color: transparent;
  text-decoration: none;
}

// Pure CSS Scroller (From: https://codepen.io/lewismcarey/pen/GJZVoG)
$duration: 30s;

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker-wrap {
  
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: 20px;
  padding-left: 100%;
  box-sizing: content-box;
  background-color: var(--ticker-bg-color);

  .ticker {

    display: inline-block;
    height: 20px;
    line-height: 20px;  
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite; 
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
   -webkit-animation-name: ticker;
           animation-name: ticker;
    -webkit-animation-duration: $duration;
            animation-duration: $duration;

    &__item {

      display: inline-block;

      padding: 0 0;
      font-size: 20px;
    }

  }

}

</style>